import {
  Badge,
  Box,
  Flex,
  Icon,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuOptionGroup,
  Spacer,
  Text,
  useBreakpointValue,
  useColorMode,
  useDisclosure,
} from "@chakra-ui/react";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { FaBell, FaDatabase } from "react-icons/fa";
import { FaCheck } from "react-icons/fa6";
import { GoDotFill } from "react-icons/go";
import { GrClose, GrPhone, GrVmMaintenance } from "react-icons/gr";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import {
  MdDarkMode,
  MdLiveHelp,
  MdOutlineDarkMode,
  MdOutlineDevices,
  MdOutlineLogout,
} from "react-icons/md";
import { SiChatbot } from "react-icons/si";
import { fetchController } from "../../utils/FetchController/fetchController";
import { getHostDomain } from "../../utils/utils";
import AuthContext from "../Auth/AuthContext";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import CallDropDownModal from "./CallDropDownModal";
import SideBar from "./SideBar";
import useTitle from "../../utils/Hook/useTitle";
import { LuFileClock } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
const smVariant = { navigation: "drawer", navigationButton: true };
const mdVariant = { navigation: "sidebar", navigationButton: false };

const Layout = ({ children }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const agency = useContext(AgencyContext);
  const {
    textColor,
    buttonColorScheme,
    cardBg: bg,
    agencyData,
    myDetails,
    titleAgencyName
  } = agency;
  useTitle(titleAgencyName);
  const baseUrl = getHostDomain();
  const url = baseUrl;
  const { logout ,authUser} = useContext(AuthContext);

  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [teams, setTeams] = useState([]);
  const variants = useBreakpointValue({ base: smVariant, md: mdVariant });
  const { isOpen: isOpenOrg, onClose, onOpen: onOpenOrg } = useDisclosure();
  const links = [
    { name: "Dashboard", path: "/dashboard" },
    { name: "Data Sources", path: "/data-sources" },
  ];
  const logoutUser = () => {
    logout();
    localStorage.removeItem("announced")
  };
  const toggleSidebar = () => setSidebarOpen(!isSidebarOpen);

  const [isOpen, setIsOpen] = useState(false);
  const [isNestedNotificationMenuOpen, setIsNestedNotificationMenuOpen] =
    useState(false);
  const [notifications, setNotifications] = useState([]);
  const [notificationsCount, setNotificationsCount] = useState();
  const [unreadNotification, setUnreadNotification] = useState([]);
  const [readedNotification, setReadedNotification] = useState([]);

  const handleOpenMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOnInnerNotificationMenu = (e) => {
    setIsNestedNotificationMenuOpen(!isNestedNotificationMenuOpen);
  };
  const loadNotification = async () => {
    try {
      const Notificationresponse = await fetchController(
        baseUrl + `/api/v1/notification/latest_notifications`,
        "GET"
      );
      const SetIconToeachNotificationType =
        Notificationresponse?.data?.items.map((val, index) => {
          return {
            created_at: val.created_at,
            created_by_id: val.created_by_id,
            id: val.id,
            link: val.link,
            message: val.message,
            notification_type: val.notification_type,
            unread: val.unread,
            icon:
              val.notification_type === "conversation" ? (
                <MdOutlineDevices />
              ) : val.notification_type === "captured_intent" ? (
                <GrVmMaintenance />
              ) : val.notification_type === "datasource" ? (
                <FaDatabase />
              ) : val.notification_type === "assistant" ? (
                <SiChatbot />
              ) : (
                ""
              ),
          };
        });
      setNotifications(SetIconToeachNotificationType);
    } catch (error) {}
  };
  const handleClickOnInnerNotificationMessage = (id) => {
    const filteredNotification = notifications.filter(
      (val, index) => val.id === id
    );
    filteredNotification[0].unread = false;
    setNotifications([...notifications]);
  };
  const handleClickOnMarkAllRead = () => {
    const data = notifications.map((val, index) => {
      return {
        created_at: val.created_at,
        created_by_id: val.created_by_id,
        id: val.id,
        link: val.link,
        message: val.message,
        icon: val.icon,
        notification_type: val.notification_type,
        unread: false,
      };
    });
    setNotifications(data);
  };

  const filterInBsisofReadUnread = (data = []) => {
    const readedNotification = data.filter(
      (val, index) => val.unread === false
    );
    const unReadedNotification = data.filter((val, index) => val.unread);

    setNotificationsCount(unReadedNotification?.length);
    setUnreadNotification(unReadedNotification);
    setReadedNotification(readedNotification);
  };

  useEffect(() => {
    const fetchData = async () => {
      const accessToken = localStorage.getItem("token");
      if (accessToken) {
        loadNotification();
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    filterInBsisofReadUnread(notifications);
  }, [notifications]);

  const { isOpen: campaignModal, onToggle: onToggleCampaignModal } =
    useDisclosure();
  const isNotificationVisible = !agency.loading && !myDetails?.agency_id;
  const navigate = useNavigate();
  const userRoles = authUser?.roles || []
  const isAgent = userRoles.includes("agent") && userRoles.length == 1
  return (
    <Flex justifyContent={"center"} alignItems={"center"}>
      <Box bg={bg}>
        <SideBar
          variant={variants?.navigation}
          isOpen={isSidebarOpen}
          onClose={toggleSidebar}
          links={links}
        />
      </Box>
      <Box
        flex="1"
        maxH={"100svh"}
        overflowY={"auto"}
        p="2"
        marginLeft={variants?.navigation === "sidebar" ? "0" : "0"}
      >
        <Box display="flex" alignItems="center">
          <Menu isOpen={isOpen} onClose={() => setIsOpen(false)}>
            <Flex
              boxShadow="sm"
              marginLeft="auto"
              alignItems="center"
              justify="center"
              p={2}
              gap={1}
              mt={1}
              borderRadius="8px"
              mr={4}
              mb={2}
            >
              {!isAgent ? <>
              <Box cursor={"pointer"} onClick={onToggleCampaignModal}>
                <MenuButton
                  as={IconButton}
                  icon={campaignModal ? <GrClose /> : <GrPhone />}
                  size={"sm"}
                  p={2}
                />
              </Box>
              <Box
                onClick={() => navigate("/logs")}
                title="Logs"
                cursor={"pointer"}
              >
                <MenuButton
                  as={IconButton}
                  icon={<LuFileClock />}
                  size={"sm"}
                  p={2}
                />
              </Box>
              <Box>
                <MenuButton
                  as={IconButton}
                  icon={
                    colorMode === "light" ? (
                      <MdOutlineDarkMode />
                    ) : (
                      <MdDarkMode />
                    )
                  }
                  onClick={toggleColorMode}
                  size={"sm"}
                  p={2}
                />{" "}
                {isNotificationVisible ? (
                  <>
                    <MenuButton
                      as={IconButton}
                      icon={<FaBell />}
                      onClick={handleOpenMenu}
                      size={"sm"}
                      p={2}
                    />
                    <Badge
                      colorScheme="red"
                      borderRadius="full"
                      position={"absolute"}
                      top={8}
                      right={113}
                      fontSize="0.8em"
                      onClick={handleOpenMenu}
                    >
                      {notificationsCount ? notificationsCount : ""}
                    </Badge>
                    <MenuList minW="270px">
                      <Flex
                        justify="space-between"
                        padding={"5px 12px"}
                        align="center"
                        width="100%"
                      >
                        <Box as="b">Notifications</Box>
                        {notificationsCount ? (
                          <>
                            <Box>
                              <Menu
                                isOpen={isNestedNotificationMenuOpen}
                                onClose={() =>
                                  setIsNestedNotificationMenuOpen(false)
                                }
                                position={"relative"}
                              >
                                <MenuButton
                                  as={HiOutlineDotsHorizontal}
                                  icon={<FaBell />}
                                  variant="ghost"
                                  onClick={handleClickOnInnerNotificationMenu}
                                  cursor={"pointer"}
                                />{" "}
                                <MenuList
                                  minW="260px"
                                  position={"absolute"}
                                  style={{ margin: "30px 0px 0px 0px" }}
                                  direction="rtl"
                                  overflow="auto"
                                  maxWidth="100%"
                                  boxShadow="md"
                                >
                                  <MenuItem
                                    onClick={handleClickOnMarkAllRead}
                                    icon={<FaCheck />}
                                  >
                                    Mark all as read
                                  </MenuItem>
                                </MenuList>
                              </Menu>
                            </Box>
                          </>
                        ) : (
                          ""
                        )}
                      </Flex>
                      {!notificationsCount && (
                        <Box>
                          <Flex direction={"column"} ml={3}>
                            <p> There are no notifications </p>
                          </Flex>
                        </Box>
                      )}
                      {unreadNotification.map((notification) => (
                        <MenuItem
                          as={"box"}
                          key={notification.id}
                          boxShadow="xs"
                        >
                          <Flex
                            direction={"row"}
                            justifyContent={"space-between"}
                            width={"100%"}
                            cursor={"context-menu"}
                          >
                            <Box>
                              <Flex direction={"column"}>
                                <Flex direction={"row"} gap={2}>
                                  <Box
                                    display={"flex"}
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    pt={2}
                                  >
                                    {notification.icon}
                                  </Box>

                                  <p
                                    cursor={"pointer"}
                                    onClick={(e) =>
                                      handleClickOnInnerNotificationMessage(
                                        notification.id
                                      )
                                    }
                                  >
                                    <Link
                                      colorScheme="blue"
                                      href={notification.link}
                                      style={{ textDecoration: "none" }}
                                      _hover={{ color: "inherit" }}
                                    >
                                      {notification.message}
                                    </Link>
                                  </p>
                                </Flex>
                                <Text pl={6} color="grey">
                                  {moment
                                    .utc(notification?.created_at)
                                    .fromNow()}
                                </Text>
                              </Flex>
                            </Box>
                            <Spacer />
                            <Box>
                              {" "}
                              {notification.unread && (
                                <>
                                  <Icon as={GoDotFill} color="blue.500" />
                                </>
                              )}
                            </Box>
                          </Flex>
                        </MenuItem>
                      ))}

                      {readedNotification.length ? (
                        <>
                          <MenuOptionGroup title="Read">
                            {readedNotification.map((notification, index) => (
                              <MenuItem
                                minH="48px"
                                position={"relative"}
                                width={"100%"}
                                boxShadow="xs"
                              >
                                <Flex
                                  direction={"row"}
                                  justifyContent={"space-between"}
                                  width={"100%"}
                                >
                                  <Box w={"100%"}>
                                    <Flex direction={"column"} key={index}>
                                      <Flex direction={"row"} gap={2}>
                                        <Box
                                          display={"flex"}
                                          justifyContent={"center"}
                                          alignItems={"center"}
                                          pt={1}
                                        >
                                          {notification.icon}
                                        </Box>
                                        <p cursor={"pointer"}>
                                          <Link
                                            colorScheme="blue"
                                            href={notification.link}
                                            style={{ textDecoration: "none" }}
                                            _hover={{ color: "inherit" }}
                                          >
                                            {notification.message}
                                          </Link>
                                        </p>
                                      </Flex>

                                      <Text pl={6} color="grey">
                                        {moment
                                          .utc(notification?.created_at)
                                          .fromNow()}
                                      </Text>
                                    </Flex>
                                  </Box>
                                  <Box></Box>
                                </Flex>
                              </MenuItem>
                            ))}
                          </MenuOptionGroup>
                        </>
                      ) : (
                        ""
                      )}
                    </MenuList>
                  </>
                ) : null}
              </Box>
              <Box
                cursor={"pointer"}
                onClick={() => {
                  window.location.href = `mailto:${
                    myDetails?.branding?.agency_mail || "support@insighto.ai"
                  }`;
                }}
              >
                <MenuButton
                  as={IconButton}
                  icon={<MdLiveHelp />}
                  size={"sm"}
                  p={2}
                />{" "}
              </Box>
                </>:null}
              <Box cursor="pointer" onClick={logoutUser}>
                <MenuButton
                  as={IconButton}
                  icon={<MdOutlineLogout />}
                  size={"sm"}
                  p={2}
                />
              </Box>
              {campaignModal ? (
                <Box
                  border={"1px solid lightgray"}
                  position={"absolute"}
                  top={59}
                  width={350}
                  boxShadow={"md"}
                  p={2}
                  right={20}
                  zIndex={3}
                  bg={bg}
                  borderRadius={"md"}
                >
                  <CallDropDownModal />
                </Box>
              ) : null}
            </Flex>
          </Menu>
        </Box>
        {children}
      </Box>
    </Flex>
  );
};

export default Layout;
