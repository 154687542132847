import {
  Button,
  useDisclosure,
  Box,
  Flex,
  Center,
  Icon,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useState ,useEffect, useContext} from "react";
import AddApiKey from "../../Modals/AddApiKey";
import Loader from "../../../utils/Loader/Loader";
import Table from "../../Tables/Table";
import { GoDotFill } from "react-icons/go";
import { MdComputer } from "react-icons/md";
import { FaUser } from "react-icons/fa";
import { fetchController } from "../../../utils/FetchController/fetchController";
import { AgencyContext } from "../AgencyProvider/AgencyProvider";
import { getHostDomain } from "../../../utils/utils";
import Paginator from "../../../utils/Loader/Paginator";
import PaginationSizeSelect from "../../../utils/Loader/PaginationSizeSelect";
import { formatDateWithAmPm } from "../../../utils/DateFormat/dateFormatWithTimeAmPm";
const ApiKey = ({ activeTab }) => {
  const baseUrl = getHostDomain();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [status, setStatus] = useState("idle");
  const [tableData, setTableData] = useState([]);
  const [heads, setHeads] = useState([]);
  const loading = status === "loading" && isInitialLoad;
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [paginationSize, setPaginationSize] = useState(10)

  const hitFetch = async () => {
    try {
      const endpoint = `/api/v1/apikey/list?page=${page}&size=${paginationSize}`;
      setStatus("loading");
      const urlHit = baseUrl + endpoint;

      const getData = await fetchController(urlHit, "GET");
      setPages(getData?.data?.pages);
      if (getData?.data) {
        const updatedData = getData.data.items.map((item, index) => {
          return {
            ["SNo"]: index + 1,
            id: item.id,
            name: item.name,
            description: item.description,
            apiKeyRole: item.api_key_role,
            ["Created at"]: formatDateWithAmPm(item.created_at, "short"),
            ["Last Used"]: formatDateWithAmPm(item?.last_used, "short"),
          };
        });

        const heads = ["SNo", "name", "description", "Created at", "Last Used"];
        const columns = heads.map((key) => ({
          Header: key,
          accessor: key,
          Filter: ({ column }) => <input {...column.filterProps} />,
        }));
        setTableData(updatedData);
        setHeads(columns);
        setStatus("success");
        setIsInitialLoad(false);
      }
    } catch (error) {
      setStatus("failure");
    }
  };
  useEffect(() => {
    if (activeTab === "api-keys") {
      hitFetch();
    } 
  }, [activeTab, page, paginationSize]);
  const agency = useContext(AgencyContext);
  const { textColor, buttonColorScheme, colorPreset } = agency;
  return (
    <Box border="1px" p="5" borderRadius="md" h="81vh" overflowY="auto">
      {" "}
      <Box h="75vh" p="5" overflowY="auto">
        <Flex justifyContent="end" alignItems="center" mb="4">
          <Button
            colorScheme={buttonColorScheme}
            display="block"
            onClick={() => {
              onOpen();
            }}
          >
            Generate a key
          </Button>
        </Flex>
        {loading ? (
          <Loader />
        ) : (
          <Box height="auto">
            <Table
              fetch={hitFetch}
              columns={heads}
              data={tableData}
              table="apikey"
            />
            <Flex
              justifyContent={"flex-end"}
              alignItems={"center"}
              marginBlock={2}
              gap={2}
            >
              <PaginationSizeSelect
                paginationSize={paginationSize}
                setPaginationSize={setPaginationSize}
                tableLength={tableData.length}
              />
              <Paginator
                colorScheme={buttonColorScheme}
                onChangePage={setPage}
                page={page}
                pages={pages}
              />
            </Flex>
          </Box>
        )}
      </Box>
      <AddApiKey
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        fetchApiKeyList={hitFetch}
        activeTab={activeTab}
        buttonColorScheme={buttonColorScheme}
        textColor={textColor}
      />
    </Box>
  );
};

export default ApiKey;
