import { Button, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { BiSupport } from "react-icons/bi";
import { FiExternalLink } from "react-icons/fi";
import { IoHelpCircleOutline } from "react-icons/io5";
import { RiDiscordLine } from "react-icons/ri";

export default function HelpMenu({ agency, navSize }) {
  const onClickDiscord = () => {
    const link =
      agency?.myDetails?.branding?.socials?.discord ||
      "https://discord.gg/3baqJDqGYQ";
    window.open(link, "_blank");
  };
  const onClickFreshDesk = () => {
    const link =
      agency?.myDetails?.branding?.socials?.docs ||
      "https://insightoai.freshdesk.com";
    window.open(link, "_blank");
  };
  const onClickAffiliate = () => {
    window.open("https://affiliates.insighto.ai/program", "_blank");
  };

  const menuItems = [
    {
      label: "Discord",
      value: onClickDiscord,
      icon: <RiDiscordLine />,
      isVisible: agency?.myDetails?.branding?.socials?.discord || true,
    },
    {
      label: "Docs",
      value: onClickFreshDesk,
      icon: <BiSupport />,
      isVisible: agency?.myDetails?.branding?.socials?.docs || true,
    },
    {
      label: "Affiliate ",
      value: onClickAffiliate,
      icon: <FiExternalLink />,
      isVisible: agency?.myDetails?.agency_id ? false : true,
    },
  ];

  return (
    <Menu>
      <MenuButton
        as={Button}
        rightIcon={<IoHelpCircleOutline />}
        textAlign={"start"}
        marginX={2}
      >
        {navSize !== "small" && "Help"}
      </MenuButton>
      <MenuList display={"flex"} justifyContent={"center"}>
        {menuItems.map((item, index) => (
          <>
            {item.isVisible && (
              <MenuItem
                key={`${item.label}_${index}`}
                onClick={item.value}
                width="14"
                gap="1"
                flexDirection="column"
                justifyContent="center"
              >
                {item.icon}
                {item.label}
              </MenuItem>
            )}
          </>
        ))}
      </MenuList>
    </Menu>
  );
}
