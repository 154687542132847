import { Box, Flex, Heading } from "@chakra-ui/react";
import React from "react";
import { LuFileClock } from "react-icons/lu";

export default function NoLogsFound({ iconSize=100, headingSize="2xl" }) {
  return (
    <Box height={"50svh"}>
      <Flex
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        height={"100%"}
      >
        <LuFileClock size={iconSize} />
        <Heading size={headingSize}>Nothing logged</Heading>
      </Flex>
    </Box>
  );
}
