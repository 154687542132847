import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Card,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Heading,
  Input,
  Spinner,
  Stack,
  Tag,
  Text,
  Textarea,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { useContext, useEffect, useRef, useState } from "react";
import { BsLightningCharge } from "react-icons/bs";
import { FaPhone } from "react-icons/fa6";

import { useForm } from "react-hook-form";
import { TbMessageChatbot } from "react-icons/tb";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { phonePromptTemplates } from "../../utils/Data/data";
import { fetchController } from "../../utils/FetchController/fetchController";
import { showToast } from "../../utils/Toast/Toast";
import PreviewVoices from "../Assistants/PreviewVoices";
import { SettingContext } from "../Settings/Reducer/SettingProvider";

import { getHostDomain } from "../../utils/utils";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";

import Layout from "../Layout/Layout";
import AddUseTool from "../Modals/AddUseTool";
import RealtimeOpenAiFields from "./RealtimeOpenAiFields";
import SelectContactSync from "./SelectContactSync";
import SelectConversationSync from "./SelectConversationSync";
import LLmModel from "./SelectLLmModel";
import WhoSpeakFirst from "./WhoSpeakFirst";
import SilenceWaitTime from "./SilenceWaitTime";
import ContactFieldsList from "../Modals/ContactList";
import PromptTemplateSelect from "./PromptTemplateSelect";

const AddEditAssistant = () => {
  const baseUrl = getHostDomain();
  const agency = useContext(AgencyContext);
  const { textColor, buttonColorScheme,myDetails } = agency;
  const finalRef = useRef(null);
  const { assistantId } = useParams();
  const navigate = useNavigate();

  const [myownVoiceOptions, setMyownVoiceOptions] = useState([]);
  const [editData, setEditData] = useState(null);
  const [member, setMember] = useState(false);
  const { state } = useContext(SettingContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
    watch,
  } = useForm({
    defaultValues: {
      assistant_type: "simple",
      templatePhonePrompt: undefined,
      voice: true,
      hide_ds: false,
      has_human_agent: false,
      use_tools: false,
      voiceLanguages: [],
      llmModel: {
        value: "gpt-3.5-turbo-0125",
        label: "GPT-3.5-Turbo-0125",
      },
      systemPrompt:
      `As an AI assistant built by a team of engineers at ${myDetails?.branding?.agency_name || "Algoscale"}, please answer the user query with the context provided.`,
      attributes: {
        conversation: false,
        contact: false,
        first_sentence: "",
        interruption_time: 10,
      },
    },
  });
  const [status, setStatus] = useState("idle");
  const [linkAssistantTools, setLinkAssistantTools] = useState([]);
  const loading = status === "loading";

  const [customFieldTags, setCustomFieldTags] = useState([]);

  const resetForm = () =>
    reset({
      assistant_type: "simple",
      voice: true,
      llmModel: {
        value: "gpt-3.5-turbo-0125",
        label: "GPT-3.5-Turbo-0125",
      },
      systemPrompt: "",
      description: "",
      name: "",
      has_human_agent: false,
      use_tools: watch("use_tools"),
      voiceLanguages: voiceSsts
        .filter((item) => item.locale_code === "en-US")
        .map((language) => ({
          label: language.name,
          value: language.locale_code,
          id: language.id,
          language_group: language.language_group,
        })),
      attributes: {
        conversation: false,
        contact: false,
      },
    });

  const onSubmitAssistantPhone = async (data) => {
    try {
      setStatus("loading");
      if (
        !data.custom_voice &&
        data.voiceLanguages.some((lang) => !lang.speaker)
      ) {
        toast.error("Please attach a speaker to the language");
        return;
      }

      await fetchController(
        baseUrl +
          (data.id ? `/api/v1/assistant/${data.id}` : "/api/v1/assistant"),
        data.id ? "PUT" : "POST",
        {
          assistant_type: data.assistant_type || "simple",
          llm_model: data.llmModel?.value || "gpt-3.5-turbo-1106",
          name: data.name,
          voice: data.voice,
          system_prompt: data.systemPrompt,
          description: data.description || "string",
          use_tools: watch("use_tools"),
          voice_languages:
            data.voiceLanguages?.map((lang) => ({
              stt: lang.id,
              tts: lang.speaker.value,
            })) || [],
          custom_voice: data.custom_voice,
          attributes: data.attributes || {},
        }
      );
      toast.success(`Assistant ${data.id ? "updated" : "created"}`);

      !data.id && resetForm();
    } catch (error) {
      toast.error("Some error occured");
    } finally {
      setStatus("idle");
      // navigate("/ai-agents/assistants")
    }
  };

  const onSubmit = async (data) => {
    try {
      setStatus("loading");

      const endpoint = data.id
        ? `/api/v1/assistant/${data.id}`
        : "/api/v1/assistant";
      const urlHit = baseUrl + endpoint;

      if (data.voice) {
        if (data.voiceLanguages.some((lang) => !lang.speaker)) {
          toast.error("Please attach a speaker to the language");
          return;
        }
      }
      const attributes = data.attributes || {};
      if (attributes?.detect_voicemail) {
        attributes["detect_voicemail"] = attributes.detect_voicemail
          ? "hangup"
          : null;
      }
      const body = {
        assistant_type: data.assistant_type || "simple",
        llm_model: data.llmModel?.value,
        name: data.name || "string",
        description: data.description || "string",
        use_tools: watch("use_tools"),
        attributes,
        system_prompt: data.systemPrompt || "",
        voice: data.voice,
        voice_languages:
          data.voiceLanguages?.map((lang) => ({
            stt: lang.id,
            tts: lang.speaker && lang.speaker.value,
          })) || [],
        custom_voice: data.custom_voice,
        show_images: data.showImages,
        hide_ds: data.hide_ds || false,
        has_human_agent: data.has_human_agent,
      };
      await fetchController(urlHit, data.id ? "PUT" : "POST", body);
      showToast(data.id ? "Assistant Updated" : "Assistant Added", "success");

      !data.id && resetForm();
    } catch (error) {
      toast.error("Some error occured");
    } finally {
      setStatus("idle");
      // navigate("/ai-agents/assistants");
    }
  };

  const loadOwnVoice = async () => {
    const endpoint = `/api/v1/voice/list`;
    const urlHit = baseUrl + endpoint;
    const getData = await fetchController(urlHit, "GET");

    const modifiedData = getData?.data?.items?.map((val, index) => {
      return {
        value: val.id,
        label: val.name,
      };
    });

    setMyownVoiceOptions(modifiedData);
  };
  const assistantTypes = [
    {
      type: "simple",
      children: TbMessageChatbot,
      title: "Chat",
      subheading:
        "Chat Assistants are optimized for fetching and answering your customers’ queries as Chatbots.",
    },
    {
      type: "phone",
      children: FaPhone,
      title: "Phone",
      subheading:
        "Conversational Voice AI Agents that can be deployed over a phone number or on web",
    },
    {
      type: "realtime_openai",
      children: BsLightningCharge,
      title: "OpenAI Realtime",
      subheading:
        "Advanced Voice from OpenAI Realtime is in BETA mode and available only to Agency or with your own keys",
    },
  ];
  useEffect(() => {
    loadOwnVoice();
  }, []);

  const [voiceSsts, setVoiceSsts] = useState([]);
  const [voices, setVoices] = useState([]);
  const languages = voiceSsts.map((language) => ({
    label: language.name,
    value: language.locale_code,
    id: language.id,
    language_group: language.language_group,
  }));
  const getAssistantTool = async () => {
    try {
      const response = await fetchController(
        baseUrl + `/api/v1/assistant/${assistantId}/LinkAssistantTool`,
        "GET"
      );
      setLinkAssistantTools(response?.data?.items || []);
      setValue("use_tools", response?.data?.items?.length > 0);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    (async () => {
      setStatus("loadingAssitant");

      const response = await fetchController(
        baseUrl + "/api/v1/voice/list/voicestt"
      );
      const { data: voicesData } = await fetchController(
        baseUrl + "/api/v1/voice/list/voicetts?size=100"
      );
      setVoices(voicesData.items);
      setVoiceSsts(response.data.items);
      setMember(state?.credits?.human_agents > 0 ? true : false);
      if (assistantId) {
        try {
          const { data } = await fetchController(
            baseUrl + `/api/v1/assistant/${assistantId}`
          );
          setEditData(data);
          await getAssistantTool();

          setValue("assistant_type", data.assistant_type);
          setValue("custom_voice", data.custom_voice);
          setValue("name", data.name);
          setValue("hide_ds", data.hide_ds || false);
          setValue("has_human_agent", data.has_human_agent || false);

          setValue("description", data.description);
          setValue("systemPrompt", data.system_prompt);

          setValue("voice", data.voice);
          const selectedStts = data.voice_languages.map(
            (voice_lang) => voice_lang.stt
          );

          const selectedVoiceLanguages = response.data.items
            .filter((lang) => selectedStts.includes(lang.id))
            .map((language) => ({
              label: language.name,
              value: language.locale_code,
              id: language.id,
              language_group: language.language_group,
            }));

          const voiceLanguages = selectedVoiceLanguages.map(
            (selectedVoiceLang, index) => {
              const speakerId = data.voice_languages[index].tts;
              const voicePerson = voicesData.items.find(
                (voice) => speakerId === voice.id
              );
              return {
                ...selectedVoiceLang,
                speaker: voicePerson && {
                  label: `${voicePerson.name} (${voicePerson.credits}) credits`,
                  value: speakerId,
                },
              };
            }
          );

          setValue("voiceLanguages", voiceLanguages);
          setValue("showImages", data.show_images);
          setValue("id", data.id);
          setValue("assistant_type", data.assistant_type);

          setValue("attributes", data.attributes);
        } catch (error) {
          resetForm();
        } finally {
          setStatus("idle");
        }
      } else {
        resetForm();

        setValue(
          "voiceLanguages",
          response.data.items
            .filter((item) => item.locale_code === "en-US")
            .map((language) => ({
              label: language.name,
              value: language.locale_code,
              id: language.id,
              language_group: language.language_group,
            }))
        );
      }
      setStatus("idle");
    })();
  }, [assistantId]);

  const loadingAssitant = status === "loadingAssitant";
  const form = useRef();

  const onSaveAndExit = async () => {
    const submit =
      watch("assistant_type") === "simple"
        ? handleSubmit(onSubmit)
        : handleSubmit(onSubmitAssistantPhone);
    submit();
    setTimeout(() => {
      if (!Object.keys(errors).length) navigate("/ai-agents/assistants");
    }, 200);
  };

  const inputRef = useRef(null);
  const currentAsstantType = assistantTypes.find(
    (type) => type.type === watch("assistant_type")
  );
  const {
    isOpen: contactIsOpen,
    onOpen: contactOnOpen,
    onClose: contactOnClose,
  } = useDisclosure();

  const {
    isOpen: isToolOpen,
    onOpen: toolOnOpen,
    onClose: toolOnClose,
  } = useDisclosure();


  const handleConversationCheck = (e) => {
    setValue("attributes.conversation", e.target.checked);
    if (!e.target.checked) {
      setValue("attributes.tool_id", "");
      setValue("attributes.conversation_syncs", []);
    }
  };

  const handleContactCheck = (e) => {
    setValue("attributes.contact", e.target.checked);
    if (!e.target.checked) {
      setValue("attributes.contact_tool_id", "");
      setValue("attributes.contact_syncs", []);
    }
  };
  const handleIsActiveCheck = (e) => {
    setValue("attributes.summarize.is_active", e.target.checked);
    if (!e.target.checked) {
      setValue("attributes.summarize.email", null);
    }
  };
  const extractCustomFieldKeywords = (text) => {
    const regex = /\{{(.*?)\}}/g;
    let matches = [];
    let match;
    while ((match = regex.exec(text)) !== null) {
      matches.push(match[1]);
    }
    return matches;
  };

  useEffect(() => {
    const keywords = extractCustomFieldKeywords(watch("systemPrompt"));
    setCustomFieldTags(keywords);
  }, [watch("systemPrompt")]);

 

  return (
    <>
      <Layout>
        <Box
          border="1px"
          borderRadius="md"
          h="90svh"
          padding={4}
          overflowY={"auto"}
        >
          <Box>
            <Box width="100%">
              <form
                ref={form}
                onSubmit={
                  watch("assistant_type") === "phone"
                    ? handleSubmit(onSubmitAssistantPhone)
                    : handleSubmit(onSubmit)
                }
              >
                <Flex
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  py={4}
                >
                  <Text
                    fontSize="xl"
                    fontWeight="bold"
                    color={textColor}
                  >
                    {watch("id") ? "Update Assistant" : "Add an Assistant"}
                  </Text>
                  {!loadingAssitant && (
                    <Stack direction={"row"} gap={3}>
                      <Button
                        type="button"
                        colorScheme={buttonColorScheme}
                        isLoading={loading}
                        onClick={onSaveAndExit}
                      >
                        Save & Exit
                      </Button>
                      {watch("id") ? (
                        <Button
                          isLoading={loading}
                          colorScheme={buttonColorScheme}
                          type="submit"
                        >
                          Save
                        </Button>
                      ) : null}
                    </Stack>
                  )}
                </Flex>

                {loadingAssitant ? (
                  <Flex justifyContent={"center"} alignItems={"center"}>
                    <Spinner />
                  </Flex>
                ) : (
                  <Flex direction={"column"} gap={4}>
                    <Flex
                      fontSize={"md"}
                      justifyContent={"flex-start"}
                      gap={2}
                      alignItems={"center"}
                    >
                      <Text>Assistant Type : </Text>
                      {assistantTypes.map(
                        ({ children: AssistantIcon, type }) => (
                          <Button
                            title={type}
                            onClick={() => {
                              setValue("assistant_type", type);
                              setValue("has_human_agent", false);

                              setValue("templatePhonePrompt", undefined);
                              setValue("custom_voice", false);
                              setValue(
                                "systemPrompt",
                                `As an AI assistant built by a team of engineers at ${myDetails?.branding?.agency_name || "Algoscale"}, please answer the user query with the context provided.`,
                              );
                              setValue(
                                "llmModel",
                                type === "realtime_openai"
                                  ? {
                                      value: "gpt-4o-realtime-preview",
                                      label:
                                        "(Beta) OpenAI GPT4o Realtime (Only Agency or BYOK)",
                                    }
                                  : {
                                      value: "gpt-3.5-turbo-0125",
                                      label: "GPT-3.5-Turbo-0125",
                                    }
                              );

                              const voice = voices.find(
                                (voice) => voice.language_group === "english"
                              );
                              setValue(
                                "voiceLanguages",
                                voiceSsts
                                  .filter(
                                    (item) => item.locale_code === "en-US"
                                  )
                                  .map((language) => ({
                                    label: language.name,
                                    value: language.locale_code,
                                    id: language.id,
                                    language_group: language.language_group,
                                    speaker: voice && {
                                      label: `${voice.name} (${voice.credits}) credits`,
                                      value: voice.id,
                                    },
                                  }))
                              );

                              setValue(
                                "voice",
                                ["simple", "phone"].includes(type)
                              );
                            }}
                            colorScheme={
                              type === watch("assistant_type")
                                ? "gray"
                                : undefined
                            }
                            isActive={type === watch("assistant_type")}
                            key={type}
                          >
                            <AssistantIcon />
                          </Button>
                        )
                      )}
                    </Flex>
                    {currentAsstantType ? (
                      <Stack spacing={1}>
                        <Heading fontSize={"md"}>
                          {currentAsstantType.title}
                        </Heading>
                        <Text>{currentAsstantType.subheading}</Text>
                      </Stack>
                    ) : null}
                    <Card variant={"elevated"} p={4}>
                      <Box>
                        <FormControl isInvalid={errors.name} mb="4">
                          <Input
                            placeholder="Name"
                            {...register("name", {
                              required: "Name is required",
                            })}
                          />
                          {errors.name && (
                            <FormErrorMessage>
                              {errors.name.message}
                            </FormErrorMessage>
                          )}
                        </FormControl>

                        <FormControl isInvalid={errors.description} mb="4">
                          <Textarea
                            placeholder="Not used by the LLM. Please use system prompt field to finetune the model"
                            {...register("description", {
                              required: "Description is required",
                            })}
                            resize="vertical"
                          />
                          {errors.description && (
                            <FormErrorMessage>
                              {errors.description.message}
                            </FormErrorMessage>
                          )}
                        </FormControl>

                        {watch("assistant_type") === "simple" && (
                          <Box>
                            <Tooltip
                              aria-label="Hide DS"
                              label="Select if you don’t want to show link to your data source in the generated answer"
                            >
                              <Box>
                                <Checkbox
                                  onChange={(e) =>
                                    setValue("hide_ds", e.currentTarget.checked)
                                  }
                                  isChecked={watch("hide_ds")}
                                  colorScheme={buttonColorScheme}
                                >
                                  Hide Data Source(s)
                                </Checkbox>
                              </Box>
                            </Tooltip>
                            <Tooltip
                              aria-label="Human Allowed"
                              label={
                                member
                                  ? "Select if you want to add the real human to this agent"
                                  : "Please upgrade to Platinum or above"
                              }
                            >
                              <Box>
                                <Checkbox
                                  disabled={!member}
                                  name="has_human_agent"
                                  onChange={(e) =>
                                    setValue(
                                      "has_human_agent",
                                      e.currentTarget.checked
                                    )
                                  }
                                  isChecked={watch("has_human_agent")}
                                  colorScheme={buttonColorScheme}
                                >
                                  Allow Human Handover
                                </Checkbox>
                              </Box>
                            </Tooltip>
                          </Box>
                        )}

                        <Flex mt={2} justifyContent={"space-between"}>
                          <Tooltip
                            aria-label="Connected Tools"
                            placement="right"
                            label={
                              "You can connect Tools after you’ve created the Assistant"
                            }
                            isDisabled={assistantId}
                          >
                            <Box>
                              <Button
                                size="xs"
                                type="button"
                                isDisabled={!assistantId}
                                colorScheme={buttonColorScheme}
                                onClick={() => {
                                  toolOnOpen();
                                }}
                              >
                                Connect Tools
                              </Button>
                            </Box>
                          </Tooltip>
                          <Box>
                            <Text as={"b"}>{"Connected Tools:"}</Text>{" "}
                            <Text as={"b"}>{linkAssistantTools?.length}</Text>
                          </Box>
                        </Flex>

                        <Box mt="4">
                          {watch("assistant_type") === "realtime_openai" ? (
                            <RealtimeOpenAiFields
                              setValue={setValue}
                              watch={watch}
                            />
                          ) : null}
                          <LLmModel
                            editData={editData}
                            watch={watch}
                            register={register}
                            setValue={setValue}
                            errors={errors}
                          />

                          <Box mt="3">
                            {watch("assistant_type") === "phone" ||
                            watch("assistant_type") ===
                              "realtime_openai" ? null : (
                              <Checkbox
                                mt="10px"
                                {...register("voice")}
                                onChange={(e) =>
                                  setValue("voice", e.target.checked)
                                }
                                colorScheme={buttonColorScheme}
                              >
                                Enable Voice
                              </Checkbox>
                            )}
                            {watch("voice") &&
                              (watch("assistant_type") === "simple" ? (
                                <>
                                  <FormLabel htmlFor="llmModel">
                                    Speech Language Recognizer
                                    <span style={{ fontSize: "12px" }}>
                                      {"(max 4)"}
                                    </span>
                                  </FormLabel>
                                  <Select
                                    value={watch("voiceLanguages")}
                                    placeholder="Select Voice Languages"
                                    options={languages}
                                    isOptionDisabled={() =>
                                      (watch("voiceLanguages") || []).length >=
                                      4
                                    }
                                    isMulti
                                    {...register("voiceLanguages", {
                                      required: "Voice Languages are required",
                                    })}
                                    onChange={(selectedOption) => {
                                      setValue(
                                        "voiceLanguages",
                                        selectedOption || null,
                                        {
                                          shouldDirty: true,
                                          shouldValidate: true,
                                        }
                                      );
                                    }}
                                  />
                                  {(watch("voiceLanguages") || []).length ? (
                                    <Box marginBlock={2}>
                                      <Flex
                                        marginBlock={1}
                                        justifyContent={"space-between"}
                                        alignItems={"center"}
                                      >
                                        <FormLabel>
                                          Select Speaker Languages
                                        </FormLabel>
                                      </Flex>
                                      <Grid gap={2}>
                                        {(watch("voiceLanguages") || []).map(
                                          (lang, currentLangIndex) => (
                                            <Grid
                                              p={1}
                                              borderRadius={"md"}
                                              border={"1px solid lightgray"}
                                              alignItems={"center"}
                                              justifyItems={"center"}
                                              gridTemplateColumns={"1fr 6fr"}
                                              key={lang.locale_code}
                                            >
                                              <Text fontWeight={"bold"}>
                                                {lang.label}
                                              </Text>
                                              <Box width={"100%"}>
                                                <Select
                                                  isOptionDisabled={(option) =>
                                                    option.voicetts_provider ===
                                                    "elevenlabs"
                                                  }
                                                  isDisabled={watch(
                                                    "custom_voice"
                                                  )}
                                                  value={
                                                    lang.speaker || undefined
                                                  }
                                                  onChange={(
                                                    selectedSpeaker
                                                  ) => {
                                                    const currentSelectedLanguages =
                                                      watch("voiceLanguages");
                                                    setValue(
                                                      "voiceLanguages",
                                                      currentSelectedLanguages.map(
                                                        (lang, index) =>
                                                          currentLangIndex ===
                                                          index
                                                            ? {
                                                                ...lang,
                                                                speaker:
                                                                  selectedSpeaker,
                                                              }
                                                            : lang
                                                      )
                                                    );
                                                  }}
                                                  options={voices
                                                    .filter(
                                                      (voiceItem) =>
                                                        voiceItem.language_group ===
                                                          "multilingual" ||
                                                        voiceItem.language_group ===
                                                          lang.language_group
                                                    )
                                                    .map((voicePerson) => ({
                                                      label: `${voicePerson.name} (${voicePerson.credits} credits)`,
                                                      value: voicePerson.id,
                                                      voicetts_provider:
                                                        voicePerson.voicetts_provider,
                                                    }))}
                                                />
                                              </Box>
                                            </Grid>
                                          )
                                        )}
                                      </Grid>
                                    </Box>
                                  ) : null}
                                </>
                              ) : watch("assistant_type") ===
                                "realtime_openai" ? null : (
                                <>
                                  <FormLabel htmlFor="voiceLanguages">
                                    Speech Language Recognizer
                                    <span style={{ fontSize: "12px" }}>
                                      {"(max 1)"}
                                    </span>
                                  </FormLabel>
                                  <Select
                                    value={watch("voiceLanguages")}
                                    placeholder="Select Voice Languages"
                                    options={languages}
                                    isOptionDisabled={() =>
                                      (watch("voiceLanguages") || []).length >=
                                      1
                                    }
                                    isMulti
                                    {...register("voiceLanguages", {
                                      required: "Voice Languages are required",
                                    })}
                                    onChange={(selectedOption) => {
                                      setValue(
                                        "voiceLanguages",
                                        selectedOption || null,
                                        {
                                          shouldDirty: true,
                                          shouldValidate: true,
                                        }
                                      );
                                    }}
                                  />
                                  {(watch("voiceLanguages") || []).length ? (
                                    <Box marginBlock={2}>
                                      <Flex
                                        marginBlock={1}
                                        justifyContent={"space-between"}
                                        alignItems={"center"}
                                      >
                                        <FormLabel>
                                          Select Speaker Languages
                                        </FormLabel>
                                        <PreviewVoices ttsList={voices} />
                                      </Flex>
                                      <Grid gap={2}>
                                        {(watch("voiceLanguages") || []).map(
                                          (lang, currentLangIndex) => (
                                            <Grid
                                              p={1}
                                              borderRadius={"md"}
                                              border={"1px solid lightgray"}
                                              alignItems={"center"}
                                              justifyItems={"center"}
                                              gridTemplateColumns={"1fr 6fr"}
                                              key={lang.locale_code}
                                            >
                                              <Text fontWeight={"bold"}>
                                                {lang.label}
                                              </Text>
                                              <Box width={"100%"}>
                                                <Select
                                                  isDisabled={watch(
                                                    "custom_voice"
                                                  )}
                                                  value={
                                                    lang.speaker || undefined
                                                  }
                                                  onChange={(
                                                    selectedSpeaker
                                                  ) => {
                                                    const currentSelectedLanguages =
                                                      watch("voiceLanguages");
                                                    setValue(
                                                      "voiceLanguages",
                                                      currentSelectedLanguages.map(
                                                        (lang, index) =>
                                                          currentLangIndex ===
                                                          index
                                                            ? {
                                                                ...lang,
                                                                speaker:
                                                                  selectedSpeaker,
                                                              }
                                                            : lang
                                                      )
                                                    );
                                                  }}
                                                  options={voices
                                                    .filter(
                                                      (voiceItem) =>
                                                        voiceItem.language_group ===
                                                          "multilingual" ||
                                                        voiceItem.language_group ===
                                                          lang.language_group
                                                    )
                                                    .map((voicePerson) => ({
                                                      label: `${voicePerson.name} (${voicePerson.credits}) credits`,
                                                      value: voicePerson.id,
                                                    }))}
                                                />
                                              </Box>
                                            </Grid>
                                          )
                                        )}
                                      </Grid>
                                    </Box>
                                  ) : null}
                                </>
                              ))}
                            {errors.voiceLanguages && (
                              <Text fontSize="11px" color="red" mt="1">
                                {errors.voiceLanguages.message}
                              </Text>
                            )}
                          </Box>
                          <Grid gap={2} mt="3">
                            {watch("assistant_type") ===
                            "realtime_openai" ? null : (
                              <>
                                <Checkbox
                                  {...register("custom_voice")}
                                  isChecked={watch("custom_voice")}
                                  onChange={(e) =>
                                    setValue("custom_voice", e.target.checked)
                                  }
                                  isDisabled={!myownVoiceOptions.length}
                                  colorScheme={buttonColorScheme}
                                >
                                  Use My Own Voice
                                </Checkbox>
                                <Text>
                                  {!myownVoiceOptions.length ? (
                                    <Link
                                      to={`/settings`}
                                      style={{ textDecoration: "none" }}
                                      _hover={{ color: "inherit" }}
                                    >
                                      Create your Own Voice
                                    </Link>
                                  ) : null}
                                </Text>
                              </>
                            )}
                          </Grid>

                          {watch("assistant_type") === "phone" && (
                            <WhoSpeakFirst
                              watch={watch}
                              getValues={getValues}
                              setValue={setValue}
                              register={register}
                            />
                          )}


                          {watch("assistant_type") === "simple" ? (
                            <Box mt="3">
                              <Flex
                                direction={"row"}
                                justifyContent={"space-between"}
                                p={"8px 0px"}
                              >
                                <Checkbox
                                  {...register("showImages")}
                                  onChange={(e) =>
                                    setValue("showImages", e.target.checked)
                                  }
                                  colorScheme={buttonColorScheme}
                                >
                                  <Tooltip
                                    label="Assistant will display the relevant extracted images from the URLs or the ingested images from the data sources"
                                    aria-label="A tooltip"
                                  >
                                    <Text>Show Images</Text>
                                  </Tooltip>
                                </Checkbox>
                              </Flex>
                            </Box>
                          ) : null}

                          <Flex gap={2}>
                            <Box width={"50%"}>
                              <Checkbox
                                mt="10px"
                                {...register("attributes.conversation")}
                                isChecked={watch("attributes.conversation")}
                                onChange={handleConversationCheck}
                                colorScheme={buttonColorScheme}
                              >
                                <Tooltip label="Automatically pushes every conversation to the selected CRM within the contact to keep everything organized. Contact would be identified by the user’s phone number or through a Captured Form converted into a Contact.">
                                  <Text>Conversation Sync</Text>
                                </Tooltip>
                              </Checkbox>
                              {watch("attributes.conversation") && (
                                <SelectConversationSync
                                  watch={watch}
                                  register={register}
                                  setValue={setValue}
                                  errors={errors}
                                />
                              )}
                            </Box>

                            <Box width={"50%"}>
                              <Checkbox
                                mt="10px"
                                {...register("attributes.contact")}
                                isChecked={watch("attributes.contact")}
                                onChange={handleContactCheck}
                                colorScheme={buttonColorScheme}
                              >
                                <Tooltip label="Automatically pushes every contact to the selected CRM to organize all contacts. Contact would be identified by the user’s phone number or through a Captured Form converted into a Contact.">
                                  <Text>Contact Sync</Text>
                                </Tooltip>
                              </Checkbox>
                              {watch("attributes.contact") && (
                                <SelectContactSync
                                  watch={watch}
                                  register={register}
                                  setValue={setValue}
                                  errors={errors}
                                />
                              )}
                            </Box>
                          </Flex>
                          <Flex gap={3} mt={2}>
                            <Box>
                              <Checkbox
                                mt="10px"
                                {...register("attributes.summarize.is_active")}
                                isChecked={watch(
                                  "attributes.summarize.is_active"
                                )}
                                onChange={handleIsActiveCheck}
                                colorScheme={buttonColorScheme}
                              >
                                <Text>
                                  Get summarized conversation ? (20 text
                                  credits)
                                </Text>
                              </Checkbox>
                            </Box>
                            <Box width={"25%"}>
                              {watch("attributes.summarize.is_active") && (
                                <>
                                  <Input
                                    type="email"
                                    placeholder="Email"
                                    {...register("attributes.summarize.email", {
                                      required: "This field is required",
                                    })}
                                  />
                                  {errors?.attributes?.summarize?.email && (
                                    <p
                                      style={{ color: "red", fontSize: "11px" }}
                                    >
                                      {
                                        errors?.attributes?.summarize?.email
                                          ?.message
                                      }
                                    </p>
                                  )}
                                </>
                              )}
                            </Box>
                          </Flex>

                          {watch("assistant_type") === "phone" && (
                            <Box>
                              <Checkbox
                                mt="10px"
                                {...register("attributes.detect_voicemail")}
                                isChecked={watch("attributes.detect_voicemail")}
                                colorScheme={buttonColorScheme}
                              >
                                <Text>
                                  Detect if answered by voicemail, fax, or
                                  answering machine, and disconnect (works only
                                  with Twilio for outbound calls
                                </Text>
                              </Checkbox>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Card>

                    <Card variant={"elevated"} p={4}>
                      <Box flex="1">
                        <Box
                          height={
                            watch("assistant_type") === "simple" ? "95%" : ""
                          }
                        >
                          <Box marginBlock={2}>
                            <PromptTemplateSelect
                              setValue={setValue}
                              watch={watch}
                            />
                          </Box>

                          <Box marginBottom={2}>
                            {customFieldTags.map((keyword, index) => (
                              <Tag key={index} colorScheme="blue" mr={2} mb={2}>
                                {keyword}
                              </Tag>
                            ))}
                          </Box>

                          <FormControl
                            height={"100%"}
                            isInvalid={errors.systemPrompt}
                          >
                            <FormLabel>Prompt</FormLabel>
                            <Textarea
                              ref={inputRef}
                              rows={
                                watch("assistant_type") === "simple" ? 27 : 18
                              }
                              placeholder="System Prompt"
                              {...register("systemPrompt", {
                                required: "System Prompt is required",
                              })}
                              resize="vertical"
                              onChange={(e) => {
                                const text = e.currentTarget.value;
                                setValue("systemPrompt", text);
                                if (text.includes("/")) contactOnOpen();
                              }}
                              h={"100%"}
                            />

                            <Text fontSize="sm" fontStyle="italic" mt={1}>
                              {`To personalize, use your contact, dynamic, or your CRM variables within your prompt. Dynamic variables can just be enclosed within double curly braces. For rest, start by pressing "/"`}
                            </Text>
                            <FormErrorMessage>
                              {errors.systemPrompt?.message}
                            </FormErrorMessage>
                          </FormControl>
                          <ContactFieldsList
                            isOpen={contactIsOpen}
                            onClose={contactOnClose}
                            getValue={getValues("systemPrompt")}
                            setValue={setValue}
                            watch={watch}
                            title="Contact Fields"
                          />
                        </Box>
                      </Box>
                    </Card>
                  </Flex>
                )}
              </form>
            </Box>
          </Box>
         
        </Box>
      </Layout>

      {isToolOpen && (
        <AddUseTool
          isOpen={isToolOpen}
          onClose={toolOnClose}
          assistantId={assistantId}
          loadAssistantTool={getAssistantTool}
          linkAssistantTools={linkAssistantTools}
        />
      )}
    </>
  );
};
export default AddEditAssistant;
