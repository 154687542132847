import { useContext, useEffect, useState } from "react";
import Loader from "../../utils/Loader/Loader";
import Layout from "../Layout/Layout";
import Table from "../Tables/Table";
import { fetchController } from "../../utils/FetchController/fetchController";
import { Box, Button, Flex, Text, Tooltip } from "@chakra-ui/react";
import FormsTour from "./FormsTour";
import { useNavigate } from "react-router-dom";
import { SettingContext } from "../Settings/Reducer/SettingProvider";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import { getHostDomain, toBoolean } from "../../utils/utils";
import Paginator from './../../utils/Loader/Paginator';
import PaginationSizeSelect from "../../utils/Loader/PaginationSizeSelect";

export default function FormListPage() {
  const baseUrl = getHostDomain();
  const [status, setStatus] = useState("idle");
  const [tableColumns, setTableColumns] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [paginationSize, setPaginationSize] = useState(10)

  async function fetchAllForms() {
    setStatus("loading");
    const response = await fetchController(
      baseUrl + `/api/v1/form/list?size=${paginationSize}&page=${page}`
    );
    setPages(response?.data?.pages);
    setTableData(
      response.data.items.map((item) => ({
        name: item.name || "",
        formFields: item.fields,
        trigger_tools: item.trigger_tools,
        fields: item.fields.map((field) => field.label).join(", "),
        form_type: item.form_type,
        trigger_instructions: item.trigger_instructions || "",
        id: item.id,
      }))
    );
    const headsData = ["name", "fields", "form_type", "trigger_instructions"];
    const columns = headsData.map((key) => ({
      Header: key,
      accessor: key,
      Filter: ({ column }) => <input {...column.filterProps} />,
    }));
    setTableColumns(columns);
    setStatus("success");
  }
  const [formTourVisibility, setFormTourVisibility] = useState(
    localStorage.getItem("formTourVisibility") ? false : true
  );
  const onCloseTour = () => {
    localStorage.setItem("formTourVisibility", false);
    setFormTourVisibility(false);
  };
  const navigate = useNavigate();
  useEffect(() => {
    fetchAllForms();
  }, [page, paginationSize]);
  const { state:activePlan, dispatch } = useContext(SettingContext);

const agency = useContext(AgencyContext);
const { textColor, buttonColorScheme,myDetails  } = agency;

const isAddFormBtnEnable=toBoolean(activePlan.credits?.form)
const toolTipMsg=myDetails && myDetails?.agency_id?`Please contact ${myDetails?.branding?.agency_name || "" } `:"Please upgrade to Gold Plan or above"

  return (
    <Layout>
      <Box
        border="1px"
        borderRadius="md"
        height={"90svh"}
        padding={2}
        overflowY={"auto"}
      >
        <Text fontSize="xl" fontWeight="bold" color={textColor}>
          Forms
        </Text>
        {formTourVisibility ? <FormsTour onCloseTour={onCloseTour} /> : null}
        <Flex justifyContent={"flex-end"} alignItems={"center"}>
          <Tooltip
            label={
              !isAddFormBtnEnable ? toolTipMsg : ""
            }
            aria-label="A tooltip"
            placement="bottom-start"
          >
            <Button
              onClick={() => navigate(`/forms/new`)}
              colorScheme={buttonColorScheme}
              isDisabled={!isAddFormBtnEnable}
            >
              Add a form
            </Button>
          </Tooltip>
        </Flex>
        <Box marginBlock={2}>
          {status === "loading" ? (
            <Loader />
          ) : (
            <>
              <Table
                fetch={fetchAllForms}
                table={"forms"}
                columns={tableColumns}
                data={tableData}
              />

              <Flex
                justifyContent={"flex-end"}
                alignItems={"center"}
                marginBlock={2}
                gap={2}
              >
                <PaginationSizeSelect
                  paginationSize={paginationSize}
                  setPaginationSize={setPaginationSize}
                  tableLength={tableData.length}
                />
                <Paginator
                  colorScheme={buttonColorScheme}
                  onChangePage={setPage}
                  page={page}
                  pages={pages}
                />
              </Flex>
            </>
          )}
        </Box>
      </Box>
    </Layout>
  );
}
