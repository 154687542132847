import { Box, Checkbox, Flex, Tooltip, Text } from "@chakra-ui/react";
import React, { useContext } from "react";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import { useFormContext } from "react-hook-form";
import CustomCheckbox from "./CustomCheckbox";

const DataSourcesField = ({ member }) => {
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  const agency = useContext(AgencyContext);
  const { buttonColorScheme } = agency;

  return (
    <Box px={4} py={2}>
      {["simple", "phone"].includes(watch("assistant_type").toLowerCase()) && (
        <Flex direction={"column"} gap={4}>
          <Box>
            <CustomCheckbox
              onChange={(e) => setValue("hide_ds", e.currentTarget.checked)}
              isChecked={watch("hide_ds")}
            >
              <Tooltip
                aria-label="Hide DS"
                label="Select if you don’t want to show link to your data source in the generated answer"
              >
                <Text> Hide Data Source(s)</Text>
              </Tooltip>
            </CustomCheckbox>
          </Box>

          <Box>
            <CustomCheckbox
              {...register("showImages")}
              isChecked={watch("showImages")}
              onChange={(e) => setValue("showImages", e.target.checked)}
            >
              <Tooltip
                label="Assistant will display the relevant extracted images from the URLs or the ingested images from the data sources"
                aria-label="A tooltip"
              >
                <Text>Show Images</Text>
              </Tooltip>
            </CustomCheckbox>
          </Box>
        </Flex>
      )}
    </Box>
  );
};

export default DataSourcesField;
