import { WarningIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  Button,
  Card,
  Flex,
} from "@chakra-ui/react";

export default function AgencyDownTime({ getMyDetails }) {
  const agencyDownTimeCount = JSON.parse(localStorage.getItem("agency_down_time_count"))

  const handleTryAgain = () => {
    localStorage.setItem("agency_down_time_count", agencyDownTimeCount + 1)
    getMyDetails()
  }

  return (
    <Flex
      height={"100vh"}
      justifyContent={"center"}
      alignItems={"center"}
      direction={"column"}
    >
      <Card
        mx={4}
        justifyContent="center"
        alignItems={"center"}
        variant={"elevated"}
        minWidth={"400px"}
      >
        <Box p={10}>
          <WarningIcon fontSize={"40px"} color={"#C53030"} />
        </Box>
        <Alert
          status="error"
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          textAlign="center"
          height="200px"
          py={4}
        >
          <AlertTitle mt={3} mb={2} fontSize="lg">
            Portal Unavailable
          </AlertTitle>
          {agencyDownTimeCount < 3 ? (
            <Button
              colorScheme="blue"
              variant="outline"
              onClick={handleTryAgain}
              mt={8}
            >
              Try Again
            </Button>
          ) : (
            <AlertDescription maxWidth="sm">
              Please retry after sometime
            </AlertDescription>
          )}
        </Alert>
      </Card>
    </Flex>
  );
}
