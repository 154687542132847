/* eslint-disable no-useless-computed-key */
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Icon,
  Tooltip,
  Checkbox,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { GoDotFill } from "react-icons/go";
import { MdComputer } from "react-icons/md";
import { FaUser } from "react-icons/fa";
import { fetchController } from "../../utils/FetchController/fetchController";
import Loader from "../../utils/Loader/Loader";
import Layout from "../Layout/Layout";
import AddWidget from "../Modals/AddWidgets";
import Table from "../Tables/Table";
import AddIntent from "../Modals/AddIntent";
import { SettingContext } from "../Settings/Reducer/SettingProvider";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import { getHostDomain, toBoolean } from "../../utils/utils";

const IntentTable = () => {
  const baseUrl = getHostDomain();
  const endpoint = "/api/v1/intent/get_my_details?page=1&size=50";
  const { state: activatedPlan, dispatch } = useContext(SettingContext);
  const [status, setStatus] = useState("idle");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isInitialLoad, setIsInitialLoad] = useState(true); // New state variable

  const [tableData, setTableData] = useState([]);
  const [heads, setHeads] = useState([]);

  const hitFetch = async () => {
    try {
      setStatus("loading");
      const urlHit = baseUrl + endpoint;

      const getData = await fetchController(urlHit, "GET");

      if (getData?.data) {
        const updatedData = getData.data.items.map((item) => {
          return {
            active: item.is_active ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  marginLeft: "15px",
                }}
              >
                <Tooltip label="Active" aria-label="Active">
                  <Center>
                    <Icon as={GoDotFill} color="green.500" />
                  </Center>
                </Tooltip>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  marginLeft: "15px",
                }}
              >
                <Tooltip label="Not active" aria-label="Not active">
                  <Center>
                    <Icon as={GoDotFill} color="red.500" />
                  </Center>
                </Tooltip>
              </div>
            ),
            Type:
              item.intent_type === "system" ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "left",
                    marginLeft: "15px",
                  }}
                >
                  <Center>
                    <Icon as={MdComputer} />
                  </Center>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "left",
                    marginLeft: "15px",
                  }}
                >
                  <Center>
                    <Icon as={FaUser} />
                  </Center>
                </div>
              ),
            userType: item.intent_type,
            ["Assistants #"]: (
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  marginLeft: "15px",
                  minWidth: "100px",
                }}
              >
                <Center>{item.assistants_count}</Center>
              </div>
            ),

            // item.assistants_count
            name: item.name,
            description: item.description,
            id: item.id,
          };
          // GrSystem;
        });

        const heads = ["Type", "name", "description", "active", "Assistants #"];
        const columns = heads.map((key) => ({
          Header: key,
          accessor: key,
          Filter: ({ column }) => <input {...column.filterProps} />,
        }));
        setTableData(updatedData);
        setHeads(columns);
        setStatus("success");
        setIsInitialLoad(false); // Set isInitialLoad to false after the initial load
      }
    } catch (error) {
      setStatus("failure");
    }
  };


  useEffect(() => {
    hitFetch();
  }, []);

  const loading = status === "loading" && isInitialLoad;
  const agency = useContext(AgencyContext);
  const { textColor, buttonColorScheme,myDetails } = agency;
  const isAddIntentBtnEnable=toBoolean(activatedPlan.advanced_intents)
  const toolTipMsg=myDetails && myDetails?.agency_id?`Please contact ${myDetails?.branding?.agency_name || "" } `:"Please upgrade to Diamond Plan"

  return (
    <>
      <Box h="75vh" overflowY="scroll" p="5">
        <Flex justifyContent="end" alignItems="center" mb="4">
          <Tooltip
            label={
              !isAddIntentBtnEnable
                ? toolTipMsg
                : ""
            }
            aria-label="A tooltip"
          >
            <Button
              colorScheme={buttonColorScheme}
              onClick={() => {
                onOpen();
              }}
              isDisabled={!isAddIntentBtnEnable}
            >
              Add an Intent
            </Button>
          </Tooltip>
        </Flex>
        {loading ? (
          <Loader />
        ) : (
          <Box height="auto">
            <Table
              fetch={hitFetch}
              columns={heads}
              data={tableData}
              table="intent"
            />
          </Box>
        )}
      </Box>
      <AddIntent
        fetchAssistants={hitFetch}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
      />
    </>
  );
};

export default IntentTable;
