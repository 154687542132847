import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { fetchController } from "../../utils/FetchController/fetchController";
import { getHostDomain } from "../../utils/utils";

export default function useConversationLogs({
  logType = "tool_function_invoke_log",
  closeDeleteAlert,
  conversationId
}) {
  const baseUrl = getHostDomain();

  const [status, setStatus] = useState("loading");
  const [response, setResponse] = useState(null);

  const fetchLogs = useCallback(async() => {
    try {
      setStatus("loading");
      const url = baseUrl + `/api/v1/${logType}/list?conversation_id=${conversationId}`;
      const response = await fetchController(url);
      setResponse(response);
      setStatus("success");
    } catch (error) {
      setStatus("error");
    }
  }, []);

  useEffect(() => {
    fetchLogs();
  }, []);

  const deleteLog = useCallback(async(log) => {
    try {
      setStatus("deleting");
      const url = baseUrl + `/api/v1/tool_function_invoke_log/${log.id}`;
      await fetchController(url, "DELETE");
      toast.success("Tool function deleted");
      setStatus("success");
      closeDeleteAlert();
      fetchLogs();
    } catch (error) {
      setStatus("deleteError");
    }
  }, []);

  return {
    status,
    response,
    isLoading: status === "loading",
    hasError: status === "error",
    fetchLogs,
    deleteLog,
  };
}
