import {
  Box,
  Button,
  Flex,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import AddUseTool from "../Modals/AddUseTool";

const ConnectTools = ({
  assistantId = null,
  getAssistantTool,
  linkAssistantTools,
}) => {
  const agency = useContext(AgencyContext);
  const { buttonColorScheme } = agency;
  const {
    isOpen: isToolOpen,
    onOpen: toolOnOpen,
    onClose: toolOnClose,
  } = useDisclosure();
  return (
    <Box px={4} py={2}>
      <Flex justifyContent={"space-between"}>
        <Tooltip
          aria-label="Connected Tools"
          placement="right"
          label={"You can connect Tools after you’ve created the Assistant"}
          isDisabled={assistantId}
        >
          <Box>
            <Button
              size="xs"
              type="button"
              isDisabled={!assistantId}
              colorScheme={buttonColorScheme}
              onClick={() => {
                toolOnOpen();
              }}
            >
              Connect Tools
            </Button>
          </Box>
        </Tooltip>
        <Box>
          <Text as={"b"}>{"Connected Tools:"}</Text>{" "}
          <Text as={"b"}>{linkAssistantTools?.length}</Text>
        </Box>
      </Flex>
      {isToolOpen && (
        <AddUseTool
          isOpen={isToolOpen}
          onClose={toolOnClose}
          assistantId={assistantId}
          loadAssistantTool={getAssistantTool}
          linkAssistantTools={linkAssistantTools}
        />
      )}
    </Box>
  );
};

export default ConnectTools;
