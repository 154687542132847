import {
  Box,
  Button,
  Flex,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import AssignIntent from "../Modals/AssignIntent";
import AssignWebhookModal from "../Settings/Webhooks/AssignWebhook";
import { getHostDomain } from "../../utils/utils";
import { fetchController } from "../../utils/FetchController/fetchController";
import { useFormContext } from "react-hook-form";

const WebhookAndIntentFields = ({ assistantId = null }) => {
  const baseUrl = getHostDomain();
  const [linkedItemIntent, setLinkedItemIntent] = useState(0);
  const [heads, setHeads] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const {
    watch,
    formState: { errors },
  } = useFormContext();
  const {
    isOpen: isWebhookAssignOpen,
    onOpen: openWebhookAssign,
    onClose: closeWebhookAssign,
  } = useDisclosure();
  const {
    isOpen: isOpenIntent,
    onOpen: onOpenIntent,
    onClose: onCloseIntent,
  } = useDisclosure();

  const loadIntent = async () => {
    try {
      setIsLoading(true);

      const getData = await fetchController(
        `${baseUrl}/api/v1/assistant/${assistantId}/list_intents`,
        "GET"
      );
      const getDataSources = await fetchController(
        `${baseUrl}/api/v1/intent/list?page=1&size=50`,
        "GET"
      );

      if (getData?.data && getDataSources?.data?.items) {
        const linkedItemIds = getData.data.items.map(
          (linkedItem) => linkedItem.id
        );
        setLinkedItemIntent(linkedItemIds.length);
        const updatedTableData = getDataSources.data.items.map((item) => ({
          ...item,
          linked: linkedItemIds.includes(item.id),
        }));

        const filteredTableData = updatedTableData.filter(
          (item) =>
            item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.description.toLowerCase().includes(searchTerm.toLowerCase())
        );

        const heads =
          getDataSources?.data?.items?.length >= 0
            ? Object.keys(getDataSources.data.items[0])
            : [];
        setHeads(heads);
        setTableData(filteredTableData);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    loadIntent();
  }, [isOpenIntent, searchTerm]);
  const agency = useContext(AgencyContext);
  const { buttonColorScheme } = agency;
  return (
    <Box px={4} py={2}>
      {watch("assistant_type") !== "phone" ? (
        <Flex mb={5} justifyContent={"space-between"}>
          <Tooltip
            aria-label="Connected Form"
            placement="right"
            label={
              assistantId
                ? ""
                : "You can connect Intent after you’ve created the Assistant"
            }
          >
            <Box>
              <Button
                size="xs"
                type="button"
                isDisabled={!assistantId}
                colorScheme={buttonColorScheme}
                onClick={() => {
                  onOpenIntent();
                }}
              >
                Assign Intent
              </Button>
            </Box>
          </Tooltip>
          <Box>
            <Text as={"b"}>{"Connected intent:"}</Text>{" "}
            <Text as={"b"}>{linkedItemIntent}</Text>
          </Box>
        </Flex>
      ) : null}
      <Flex justifyContent={"space-between"}>
        <Tooltip
          aria-label="Connected Form"
          placement="right"
          label={
            assistantId
              ? ""
              : "You can connect Webhook after you’ve created the Assistant"
          }
        >
          <Box>
            <Button
              size="xs"
              type="button"
              isDisabled={!assistantId}
              colorScheme={buttonColorScheme}
              onClick={() => {
                openWebhookAssign();
              }}
            >
              Assign Webhook
            </Button>
          </Box>
        </Tooltip>
      </Flex>
      {isOpenIntent && (
        <AssignIntent
          isOpen={isOpenIntent}
          onOpen={onOpenIntent}
          onClose={onCloseIntent}
          selectedItem={{ id: assistantId }}
          tableData={tableData}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setSearchTerm={setSearchTerm}
          loadIntent={loadIntent}
        />
      )}

      {isWebhookAssignOpen ? (
        <AssignWebhookModal
          isOpen={isWebhookAssignOpen}
          selectedAssistant={{ id: assistantId }}
          onClose={closeWebhookAssign}
        />
      ) : null}
    </Box>
  );
};

export default WebhookAndIntentFields;
