import {
  Box,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Td,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import moment from "moment";
import { IoEllipsisVerticalSharp, IoEyeOutline } from "react-icons/io5";
import { LiaToolsSolid } from "react-icons/lia";
import { MdCallReceived, MdDelete } from "react-icons/md";
import { RxComponentPlaceholder } from "react-icons/rx";

export default function LogRow(props) {
  return (
    <Tr>
      <Td>
        <Flex gap={2}>
          <Box as="span">
            {props.log.tool_icon ? (
              <img
                src={props.log.tool_icon}
                alt="Logo"
                height={"16px"}
                width={"16px"}
              />
            ) : (
              <LiaToolsSolid
                size={20}
                style={{
                  margin: "auto",
                }}
              />
            )}
          </Box>
          <Box as="span">{props.log.tool_name}</Box>
        </Flex>
      </Td>
      <Td>{props.log.tool_user_name}</Td>
      <Td>{props.log.tool_function_name}</Td>
      <Td>{props.log.widget_name}</Td>
      <Td>
        <Tooltip label={props.log?.created_at}>
          {props.log?.created_at
            ? moment.utc(props.log?.created_at).fromNow()
            : null}
        </Tooltip>
      </Td>
      <Td>
        <Menu>
          <MenuButton
            colorScheme="blue"
            size={"sm"}
            as={IconButton}
            aria-label="Options"
            icon={<IoEllipsisVerticalSharp />}
            variant="outline"
          />
          <MenuList>
            {props.onOpenShowModal && (
              <MenuItem
                onClick={() => props.onOpenShowModal(props.log)}
                icon={<IoEyeOutline />}
              >
                Show
              </MenuItem>
            )}
            {props.onDeleteLog && (
              <MenuItem
                onClick={() => props.onDeleteLog(props.log)}
                icon={<MdDelete />}
              >
                Delete
              </MenuItem>
            )}
          </MenuList>
        </Menu>
      </Td>
    </Tr>
  );
}
