import {
  Alert,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { fetchController } from "../../utils/FetchController/fetchController";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { getHostDomain } from "../../utils/utils";

export default function EditWidget({ isOpen, onClose, selectedWidget, refreshTable }) {
  const baseUrl = getHostDomain();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
  } = useForm({
    defaultValues: {
      name: "",
      description: "",
      widget_type: ""
    },
  });

  const onSubmit = async (data) => {
    try {
      const payload = {
        ...data,
      };
      const response = await fetchController(
        `${baseUrl}/api/v1/widget/${selectedWidget.id}`,
        "PUT",
        payload
      );

      toast.success(response?.message || "Widget updated successfully");
      refreshTable();
    } catch (error) {
      console.error(error);
      toast.error(error?.message || "Something went wrong");
    } finally {
      handleClose();
    }
  };

  useEffect(() => {
    if (selectedWidget) {
      setValue("name", selectedWidget.name);
      setValue("description", selectedWidget.description);
      setValue("widget_type", selectedWidget.widget_type);
    }
  }, [selectedWidget]);

  const handleClose = () => {
    onClose();
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Widget</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody pb={6} display={"flex"} flexDirection={"column"} gap={4}>
            <FormControl isInvalid={errors.name}>
              <FormLabel>Name</FormLabel>
              <Input
                placeholder="Enter name"
                {...register("name", { required: "Name is required" })}
              />
              <FormErrorMessage>
                {errors.name && errors.name.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.description}>
              <FormLabel>{"Description"}</FormLabel>
              <Textarea
                placeholder="Enter description"
                {...register("description", {
                  required: "Description is required",
                })}
                resize="vertical"
                minHeight="80px"
              />
              <FormErrorMessage>
                {errors.description && errors.description.message}
              </FormErrorMessage>
            </FormControl>

            <Alert status="info">
              {
                "Create a new Widget for configuration changes."
              }
            </Alert>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              type="submit"
              mr={3}
              isLoading={isSubmitting}
            >
              Update
            </Button>
            <Button onClick={handleClose}>Cancel</Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
