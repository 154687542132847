import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Switch,
  Textarea,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { fetchController } from "../../utils/FetchController/fetchController";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { getHostDomain } from "../../utils/utils";

export default function AddEditToolModal({
  isOpen,
  onClose,
  refreshToolList,
  toolId = null,
  openToolFunctions,
  setFuncionBaseUrl,
}) {

  const baseUrl = getHostDomain();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      name: "",
      baseUrl: "",
      description: "",
      enable: true,
    },
  });

  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);

  const getToolListById = async () => {
    try {
      setIsEditing(true);
      setLoading(true);
      const response = await fetchController(
        baseUrl + `/api/v1/tool/${toolId}`,
        "GET"
      );

      setValue("name", response?.data?.name);
      setValue("baseUrl", response?.data?.base_url);
      setValue("description", response?.data?.description);
      setValue("enable", response?.data?.enabled);

      setFuncionBaseUrl(response?.data?.base_url)
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (toolId) {
      getToolListById();
    }
  }, [toolId]);

  const onSubmit = async (data) => {
    try {
      await fetchController(
        baseUrl +
        `${isEditing ? `/api/v1/tool/${toolId}` : "/api/v1/tool"}`,
        `${isEditing ? "PUT" : "POST"}`,
        {
          name: data.name,
          description: data.description,
          tool_type: "user",
          base_url: data.baseUrl,
          sdk: {},
          authentication: {},
          tool_provider: "custom",
          enabled: data.enable,
          category: "Others",
        }
      );
      toast.success(
        `${isEditing ? "Tool updated successfully" : "Tool created successfully"
        }`
      );
      reset();
      onClose();
      refreshToolList();
    } catch (e) {
      console.error(e);
      toast.error("Something went wrong");
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} blockScrollOnMount={false}>
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>{isEditing ? "Edit Tool" : "Add Tool"}</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            {loading ? (
              <Flex justifyContent={"center"} alignItems={"center"}>
                <Spinner />
              </Flex>
            ) : (
              <Flex direction={"column"} gap={4}>
                <FormControl isInvalid={errors.name}>
                  <FormLabel>Name</FormLabel>
                  <Input
                    placeholder={"Enter tool name"}
                    {...register("name", { required: "Name is required" })}
                  />
                  {errors.name && (
                    <FormErrorMessage>{errors.name.message}</FormErrorMessage>
                  )}
                </FormControl>

                <FormControl isInvalid={errors.baseUrl}>
                  <FormLabel>Base URL</FormLabel>
                  <Input
                    placeholder={"Enter base URL"}
                    {...register("baseUrl", {
                      required: "Base URL is required",
                    })}
                  />
                  {errors.baseUrl && (
                    <FormErrorMessage>
                      {errors.baseUrl.message}
                    </FormErrorMessage>
                  )}
                </FormControl>

                <FormControl isInvalid={errors.baseUrl}>
                  <FormLabel>Description</FormLabel>
                  <Textarea
                    placeholder={"Enter tool description"}
                    {...register("description", {
                      required: "Description is required",
                    })}
                  />
                  {errors.description && (
                    <FormErrorMessage>
                      {errors.description.message}
                    </FormErrorMessage>
                  )}
                </FormControl>

                <FormControl isInvalid={errors.enable}>
                  <FormLabel>Do you want to enable this Tool?</FormLabel>
                  <Switch
                    id="enable-tool"
                    size={"md"}
                    {...register("enable")}
                  />
                </FormControl>
              </Flex>
            )}
          </ModalBody>
          <ModalFooter>
            <Flex justifyContent={"space-between"} gap={2} width={"100%"}>
              <Box>
                {isEditing && (
                  <Button
                    colorScheme="blue"
                    onClick={() => openToolFunctions()}
                    size={"sm"}
                  >
                    {"Functions"}
                  </Button>
                )}
              </Box>
              <ButtonGroup size={"sm"} spacing={"2"}>
                <Button
                  type="submit"
                  colorScheme="blue"
                  isLoading={isSubmitting}
                >
                  {isEditing ? "Update" : "Add"}
                </Button>
                <Button colorScheme="blue" onClick={onClose}>
                  {"Cancel"}
                </Button>
              </ButtonGroup>
            </Flex>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
