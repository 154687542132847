import { Box, Button, Flex, Heading, IconButton, useDisclosure } from "@chakra-ui/react";
import Table from "../Tables/Table";
import { toast } from "react-toastify";
import Loader from "../../utils/Loader/Loader";
import { useContext, useEffect, useState } from "react";
import { fetchController } from "../../utils/FetchController/fetchController";
import Layout from "../Layout/Layout";
import AddContactCustomField from "../Modals/AddEditContactCustomField";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import { getHostDomain } from "../../utils/utils";
import Paginator from "../../utils/Loader/Paginator";
import PaginationSizeSelect from "../../utils/Loader/PaginationSizeSelect";

export default function CustomFields() {
  const baseUrl = getHostDomain();
  const navigate = useNavigate();

  const [heads, setHeads] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [paginationSize, setPaginationSize] = useState(10)

  const {
    isOpen: isCustomFieldModalOpen,
    onOpen: openCustomFieldModal,
    onClose: closeCustomFieldModal,
  } = useDisclosure();

  const fetchCustomFields = async () => {
    const endpoint = `/api/v1/contact_custom_field/list?page=${page}&size=${paginationSize}`;
    const url = baseUrl;
    const urlHit = url + endpoint;
    try {
      setLoading(true);
      const getData = await fetchController(urlHit, "GET");
      setPages(getData?.data?.pages);
      if (getData?.data) {
        const headsData = ["custom_field_name", "custom_field_type"];
        const columns = headsData.map((key) => ({
          Header: key,
          accessor: key,
          Filter: ({ column }) => <input {...column.filterProps} />,
        }));
        setTableData(getData.data.items);
        setHeads(columns);
      }
    } catch (error) {
      toast.error(error?.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCustomFields();
  }, [page, paginationSize]);
  const agency = useContext(AgencyContext);
  const { textColor, buttonColorScheme, } = agency;
  return (
    <Layout>
      <Box
        border="1px"
        borderColor={"black"}
        borderRadius={"md"}
        h="90svh"
        p={5}
        overflowY={"auto"}
      >
        <Box h="75vh" overflowY="auto" p="5">
          <Flex mb="4" justifyContent={"space-between"}>
            <Heading color={textColor} fontSize="xl">
              <IconButton
                variant="outline"
                aria-label="Go Back"
                icon={<ArrowBackIcon />}
                size={"sm"}
                color={textColor}
                onClick={() => navigate("/contacts")}
                mr={2}
              />
              {"Custom Fields"}
            </Heading>
            <Button
              colorScheme={buttonColorScheme}
              onClick={() => {
                openCustomFieldModal();
              }}
            >
              {"Add Custom Field"}
            </Button>
          </Flex>
          {loading ? (
            <Loader />
          ) : (
            <>
              <Table
                fetch={fetchCustomFields}
                columns={heads}
                data={tableData}
                table="contactCustomField"
              />
              <Flex
                justifyContent={"flex-end"}
                alignItems={"center"}
                marginBlock={2}
                gap={2}
              >
                <PaginationSizeSelect
                  paginationSize={paginationSize}
                  setPaginationSize={setPaginationSize}
                  tableLength={tableData.length}
                />
                <Paginator
                  colorScheme={buttonColorScheme}
                  onChangePage={setPage}
                  page={page}
                  pages={pages}
                />
              </Flex>
            </>
          )}
        </Box>
      </Box>
      <AddContactCustomField
        isOpen={isCustomFieldModalOpen}
        onClose={closeCustomFieldModal}
        reFreshTable={fetchCustomFields}
        buttonColorScheme={buttonColorScheme}
      />
    </Layout>
  );
}
