import { Box, Checkbox, Text } from "@chakra-ui/react";
import React, { useContext } from "react";
import RealtimeOpenAiFields from "./RealtimeOpenAiFields";
import LLmModel from "./SelectLLmModel";
import { useFormContext } from "react-hook-form";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";

const LLMrelated = ({ editData }) => {
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  const agency = useContext(AgencyContext);
  const { buttonColorScheme } = agency;

  return (
    <Box px={4} py={2}>
      <Box mt="4">
        <LLmModel
          editData={editData}
          watch={watch}
          register={register}
          setValue={setValue}
          errors={errors}
        />

        {watch("assistant_type") === "phone" && (
          <Box>
            <Checkbox
              mt="10px"
              {...register("attributes.detect_voicemail")}
              isChecked={watch("attributes.detect_voicemail")}
              colorScheme={buttonColorScheme}
            >
              <Text>
                Detect if answered by voicemail, fax, or answering machine, and
                disconnect (works only with Twilio for outbound calls)
              </Text>
            </Checkbox>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default LLMrelated;
