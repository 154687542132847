import { Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Activity from "./components/Activity/Activity";
import AiAgents from "./components/Ai-Agents/AiAgents";
import SingleAssistant from "./components/Assistants/SingleAssistants";
import ProtectedRoute from "./components/Auth/ProtectedRoute";
import Contact from "./components/Contacts/Contact";
import CustomFields from "./components/Contacts/CustomFields";
import Conversations from "./components/Conversations/Conversations";
import Dashboard from "./components/Dashboard/Dashboard";
import ConfigureForms from "./components/Forms/ConfigureForms";
import FormsListPage from "./components/Forms/FormsListPage";
import Intent from "./components/Intent/Intent";
import LiveAgentChats from "./components/Live-Agent-Chats";
import NotFoundPage from "./components/Notfound";
import PlaygroundPage from "./components/Playground";
import BillingSuccess from "./components/Settings/Billing/BillingSuccess";
import Settings from "./components/Settings/Settings";
import Tools from "./components/Tools";
import CallWidgetCustomizePage from "./components/Widgets/CallwidgetCustomizePage";
import CustomizeWidget from "./components/Widgets/CustomizeWidget";

import { ToastContainer } from "react-toastify";
import Agency from "./components/Agency/Agency";
import OAuthRedirectLoginToken from "./components/Auth/OAuthRedirectLoginToken";
import RedirectAuthenticated from "./components/Auth/RedirectAuthenticated";
import { AgencyContext } from "./components/Settings/AgencyProvider/AgencyProvider";
import { useContext } from "react";
import useFavicon from "./utils/Hook/useFavicon";
import Login from "./components/Auth/Login";
import Register from "./components/Auth/Register";
import ForgotPassword from "./components/Auth/ForgotPassword";
import ResetPassword from "./components/Auth/ResetPassword";
import VerifyEmail from "./components/Auth/VerifyEmail";
import WebcallWidgetCustomizationPage from "./components/Widgets/WebcallWidgetCustomizationPage";
import LogsPage from "./components/Logs";
import ConversationFlow from "./components/ConversationFlow";
import AddEditAssistant from "./components/Assistants/AddEditAssistant";
import AddEditAssistant_new from "./components/Assistants/AddEditAssistant_new";
import Library from "./components/Library/Library";
import AgencyDownTime from "./components/Auth/AgencyDownTime"
import Loader from "./utils/Loader/Loader";

function App() {
  const agency = useContext(AgencyContext);
  const { loading, faviconUrl, myDetails, getMyDetails } = agency;

  const logoUrl = !loading
    ? faviconUrl
    : null;

  useFavicon(logoUrl);

  const isValidDomain = (domain) =>
    /^(https?:\/\/)?(localhost|app\.insighto\.ai)(:\d+)?(\/.*)?$/i.test(domain);

  if(myDetails && localStorage.getItem("agency_down_time_count")) localStorage.removeItem("agency_down_time_count")

  if (loading) {
    return <Loader />;
  }

  if(!isValidDomain(window.location.origin) && !myDetails) {
    return <AgencyDownTime getMyDetails={getMyDetails} />
  }
  
  return (
    <>
      <Routes>
        <Route path="/" element={<RedirectAuthenticated component={Login} />} />
        <Route
          path="/login"
          element={<RedirectAuthenticated component={Login} />}
        />
        <Route
          path="/register"
          element={<RedirectAuthenticated component={Register} />}
        />
        <Route path="/auth" element={<OAuthRedirectLoginToken />} />
        <Route
          path="/forgot-password"
          element={<RedirectAuthenticated component={ForgotPassword} />}
        />
        <Route
          path="/reset-password"
          element={<RedirectAuthenticated component={ResetPassword} />}
        />
        <Route
          path="/verify-email"
          element={<RedirectAuthenticated component={VerifyEmail} />}
        />

        <Route
          path="/dashboard"
          element={<ProtectedRoute component={Dashboard} />}
        />
        <Route path="/forms">
          <Route
            element={<ProtectedRoute component={ConfigureForms} />}
            path=":formId"
          />
          <Route
            element={<ProtectedRoute component={ConfigureForms} />}
            path="new"
          />
          <Route
            element={<ProtectedRoute component={FormsListPage} />}
            path=""
          />
        </Route>
        <Route
          path="/assistants/:uuid"
          element={<ProtectedRoute component={SingleAssistant} />}
        />

        <Route
          path="/live-agent-chats"
          element={<ProtectedRoute component={LiveAgentChats} />}
        />
        <Route
          path="/widgets/:widgetId/chat/customize"
          element={<ProtectedRoute component={CustomizeWidget} />}
        />
        <Route
          path="/widgets/:widgetId/html_call/customize"
          element={<ProtectedRoute component={CallWidgetCustomizePage} />}
        />
        <Route
          path="/widgets/:widgetId/web_call/customize"
          element={
            <ProtectedRoute component={WebcallWidgetCustomizationPage} />
          }
        />
        {/* <Route
        path="/conversations"
        element={<ProtectedRoute component={Conversations} />}
      />
      <Route
        path="/conversations/:conversationId"
        element={<ProtectedRoute component={Conversations} />}
      /> */}
        <Route
          path="/billing/success"
          element={<ProtectedRoute component={BillingSuccess} />}
        />
        <Route
          path="/settings"
          element={<ProtectedRoute component={Settings} />}
        />
        <Route path="/logs" element={<ProtectedRoute component={LogsPage} />} />
        <Route
          path="/settings/:name"
          element={<ProtectedRoute component={Settings} />}
        />
        <Route
          path="/conversationFlow"
          element={<ProtectedRoute component={ConversationFlow} />}
        />
        <Route
          path="/playground"
          element={<ProtectedRoute component={PlaygroundPage} />}
        />
        <Route
          path="/ai-agents"
          element={<ProtectedRoute component={AiAgents} />}
        />
        <Route
          path="/intents"
          element={<ProtectedRoute component={Intent} />}
        />
        <Route
          path="/ai-agents/:name"
          element={<ProtectedRoute component={AiAgents} />}
        />
        
         <Route
          path="/ai-agents/assistants/new"
          element={<ProtectedRoute component={AddEditAssistant_new} />}
        />
        
         <Route
          path="/ai-agents/assistants/:assistantId"
          element={<ProtectedRoute component={AddEditAssistant_new} />}
        />
        <Route
          path="/library/"
          element={<ProtectedRoute component={Library} />}
        />
        <Route
          path="/library/:name"
          element={<ProtectedRoute component={Library} />}
        />
        <Route path="/tools" element={<ProtectedRoute component={Tools} />} />

        <Route
          path="/engagements"
          element={<ProtectedRoute component={Activity} />}
        />
        <Route
          path="/engagements/:name"
          element={<ProtectedRoute component={Activity} />}
        />
        <Route
          path="/engagements/:name/:conversationId"
          element={<ProtectedRoute component={Conversations} />}
        />
        <Route
          path="/contacts"
          element={<ProtectedRoute component={Contact} />}
        />
        <Route
          path="/contacts/custom-field"
          element={<ProtectedRoute component={CustomFields} />}
        />
        <Route path="/agency" element={<ProtectedRoute component={Agency} />} />

        <Route path="*" element={<ProtectedRoute component={NotFoundPage} />} />
      </Routes>

      <ToastContainer autoClose={3000} />
    </>
  );
}

export default App;
