import React, { useContext } from "react";
import WhoSpeakFirst from "./WhoSpeakFirst";
import { Box, Text, FormLabel, Grid, Checkbox, Flex } from "@chakra-ui/react";
import Select from "react-select";
import { useFormContext } from "react-hook-form";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import PreviewVoices from "./PreviewVoices";
import { Link } from "react-router-dom";
import RealtimeOpenAiFields from "./RealtimeOpenAiFields";
import CustomCheckbox from "./CustomCheckbox";

const VoicesField = ({ myownVoiceOptions, languages, voices }) => {
  const {
    register,
    getValues,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  return (
    <Box px={4} py={2}>
      <Box>
        {watch("assistant_type") === "phone" ||
        watch("assistant_type") === "realtime_openai" ? null : (
          <CustomCheckbox
            mt="10px"
            {...register("voice")}
            isChecked={watch("voice")}
            onChange={(e) => setValue("voice", e.target.checked)}
          >
            Enable Voice
          </CustomCheckbox>
        )}
        {watch("voice") &&
          (watch("assistant_type") === "simple" ? (
            <>
              <FormLabel htmlFor="llmModel">
                Speech Language Recognizer
                <span style={{ fontSize: "12px" }}>{"(max 4)"}</span>
              </FormLabel>
              <Select
               menuPortalTarget={document.body}  
               styles={{
                 menuPortal: (base) => ({ ...base, zIndex: 9999 }) // Ensures it displays above other components
               }}
                value={watch("voiceLanguages")}
                placeholder="Select Voice Languages"
                options={languages}
                isOptionDisabled={() =>
                  (watch("voiceLanguages") || []).length >= 4
                }
                isMulti
                {...register("voiceLanguages", {
                  required: "Voice Languages are required",
                })}
                onChange={(selectedOption) => {
                  setValue("voiceLanguages", selectedOption || null, {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                }}
              />
              {(watch("voiceLanguages") || []).length ? (
                <Box marginBlock={2}>
                  <Flex
                    marginBlock={1}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <FormLabel>Select Speaker Languages</FormLabel>
                  </Flex>
                  <Grid gap={2}>
                    {(watch("voiceLanguages") || []).map(
                      (lang, currentLangIndex) => (
                        <Grid
                          p={1}
                          borderRadius={"md"}
                          border={"1px solid lightgray"}
                          alignItems={"center"}
                          justifyItems={"center"}
                          gridTemplateColumns={"1fr 6fr"}
                          key={lang.locale_code}
                        >
                          <Text fontWeight={"bold"}>{lang.label}</Text>
                          <Box width={"100%"}>
                            <Select
                              menuPortalTarget={document.body}  
                              styles={{
                                menuPortal: (base) => ({ ...base, zIndex: 9999 }) // Ensures it displays above other components
                              }}
                              isOptionDisabled={(option) =>
                                option.voicetts_provider === "elevenlabs"
                              }
                              isDisabled={watch("custom_voice")}
                              value={lang.speaker || undefined}
                              onChange={(selectedSpeaker) => {
                                const currentSelectedLanguages =
                                  watch("voiceLanguages");
                                setValue(
                                  "voiceLanguages",
                                  currentSelectedLanguages.map((lang, index) =>
                                    currentLangIndex === index
                                      ? {
                                          ...lang,
                                          speaker: selectedSpeaker,
                                        }
                                      : lang
                                  )
                                );
                              }}
                              options={voices
                                .filter(
                                  (voiceItem) =>
                                    voiceItem.language_group ===
                                      "multilingual" ||
                                    voiceItem.language_group ===
                                      lang.language_group
                                )
                                .map((voicePerson) => ({
                                  label: `${voicePerson.name} (${voicePerson.credits} credits)`,
                                  value: voicePerson.id,
                                  voicetts_provider:
                                    voicePerson.voicetts_provider,
                                }))}
                            />
                          </Box>
                        </Grid>
                      )
                    )}
                  </Grid>
                </Box>
              ) : null}
            </>
          ) : watch("assistant_type") === "realtime_openai" ? null : (
            <>
              <FormLabel htmlFor="voiceLanguages">
                Speech Language Recognizer
                <span style={{ fontSize: "12px" }}>{"(max 1)"}</span>
              </FormLabel>
              <Select
                value={watch("voiceLanguages")}
                placeholder="Select Voice Languages"
                options={languages}
                isOptionDisabled={() =>
                  (watch("voiceLanguages") || []).length >= 1
                }
                isMulti
                {...register("voiceLanguages", {
                  required: "Voice Languages are required",
                })}
                onChange={(selectedOption) => {
                  setValue("voiceLanguages", selectedOption || null, {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                }}
                menuPortalTarget={document.body}  
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }) // Ensures it displays above other components
                }}
              />
              {(watch("voiceLanguages") || []).length ? (
                <Box marginBlock={2}>
                  <Flex
                    marginBlock={1}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <FormLabel>Select Speaker Languages</FormLabel>
                    <PreviewVoices ttsList={voices} />
                  </Flex>
                  <Grid gap={2}>
                    {(watch("voiceLanguages") || []).map(
                      (lang, currentLangIndex) => (
                        <Grid
                          p={1}
                          borderRadius={"md"}
                          border={"1px solid lightgray"}
                          alignItems={"center"}
                          justifyItems={"center"}
                          gridTemplateColumns={"1fr 6fr"}
                          key={lang.locale_code}
                        >
                          <Text fontWeight={"bold"}>{lang.label}</Text>
                          <Box width={"100%"}>
                            <Select
                              menuPortalTarget={document.body}  
                              styles={{
                                menuPortal: (base) => ({ ...base, zIndex: 9999 }) // Ensures it displays above other components
                              }}
                              isDisabled={watch("custom_voice")}
                              value={lang.speaker || undefined}
                              onChange={(selectedSpeaker) => {
                                const currentSelectedLanguages =
                                  watch("voiceLanguages");
                                setValue(
                                  "voiceLanguages",
                                  currentSelectedLanguages.map((lang, index) =>
                                    currentLangIndex === index
                                      ? {
                                          ...lang,
                                          speaker: selectedSpeaker,
                                        }
                                      : lang
                                  )
                                );
                              }}
                              options={voices
                                .filter(
                                  (voiceItem) =>
                                    voiceItem.language_group ===
                                      "multilingual" ||
                                    voiceItem.language_group ===
                                      lang.language_group
                                )
                                .map((voicePerson) => ({
                                  label: `${voicePerson.name} (${voicePerson.credits}) credits`,
                                  value: voicePerson.id,
                                }))}
                            />
                          </Box>
                        </Grid>
                      )
                    )}
                  </Grid>
                </Box>
              ) : null}
            </>
          ))}
        {errors.voiceLanguages && (
          <Text fontSize="11px" color="red" mt="1">
            {errors.voiceLanguages.message}
          </Text>
        )}
      </Box>
      <Grid gap={2} mt="3">
        {watch("assistant_type") === "realtime_openai" ? null : (
          <>
            <CustomCheckbox
              {...register("custom_voice")}
              isChecked={watch("custom_voice")}
              onChange={(e) => setValue("custom_voice", e.target.checked)}
              isDisabled={!myownVoiceOptions.length}
            >
              Use My Own Voice
            </CustomCheckbox>
            <Text>
              {!myownVoiceOptions.length ? (
                <Link
                  to={`/settings`}
                  style={{ textDecoration: "none" }}
                  _hover={{ color: "inherit" }}
                >
                  Create your Own Voice
                </Link>
              ) : null}
            </Text>
          </>
        )}
      </Grid>

      {watch("assistant_type") === "phone" && (
        <WhoSpeakFirst
          watch={watch}
          getValues={getValues}
          setValue={setValue}
          register={register}
        />
      )}

      {watch("assistant_type") === "realtime_openai" ? (
        <RealtimeOpenAiFields setValue={setValue} watch={watch} />
      ) : null}
    </Box>
  );
};

export default VoicesField;
