import { useContext } from "react";
import AuthContext from "./AuthContext";
import Loader from "../../utils/Loader/Loader";
import { useMediaQuery } from "@chakra-ui/react";
import DesktopBrowserRecommended from "./DesktopBrowserRecommended";

const RedirectAuthenticated = ({ component: Component, ...rest }) => {
  const { isAuthenticated, isLoading, authUser } = useContext(AuthContext);

  const [isSmallerThan800] = useMediaQuery("(max-width: 800px)");

  const userRoles = authUser?.roles || [];
  const isAgent = userRoles.includes("agent") && userRoles.length == 1;
  const redirectRoute = isAgent ? "/live-agent-chats" : "/dashboard";
  if (isSmallerThan800) {
    return <DesktopBrowserRecommended />;
  }

  if (isLoading) {
    return <Loader />;
  }

  if (isAuthenticated) {
    window.location.href = redirectRoute;
  } else {
    return <Component {...rest} />;
  }
};

export default RedirectAuthenticated;
