import {
  Box,
  Button,
  useDisclosure,
  Flex,
  Tooltip,
  Text,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import LinkFormAssistantModal from "../Modals/LinkFormAssistantModal";
import { getHostDomain } from "../../utils/utils";
import { fetchController } from "../../utils/FetchController/fetchController";

const FormFields = ({ assistantId = null, linkAssistantForms = [] }) => {
  const baseUrl = getHostDomain();
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [heads, setHeads] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [linkedItem, setLinkedItem] = useState(0);
  const [paginationSize, setPaginationSize] = useState(10)

  const {
    isOpen: isFormOpen,
    onOpen: onOpenForm,
    onClose: onFormClose,
  } = useDisclosure();
  const agency = useContext(AgencyContext);
  const { buttonColorScheme } = agency;

  const assistantFormListEndpoint = `/api/v1/assistant/${assistantId}/LinkAssistantForm?page=1&size=50`;
  const assistantFormUrl = baseUrl + assistantFormListEndpoint;
  const DataSourcesEndpoint = `/api/v1/form/list?page=${page}&size=${paginationSize}`;
  const dataSourcesUrl = baseUrl + DataSourcesEndpoint;
  const loadForm = async () => {
    try {
      setIsLoading(true);

      const getDataSources = await fetchController(dataSourcesUrl, "GET");
      const getData = await fetchController(assistantFormUrl, "GET");

      if (getData?.data && getDataSources?.data?.items) {
        const linkedItemIds = getData.data.items.map(
          (linkedItem) => linkedItem.form_id
        );
        setLinkedItem(linkedItemIds.length);
        const updatedTableData = getDataSources.data.items.map((item) => ({
          ...item,
          linked: linkedItemIds.includes(item.id),
        }));
        const filteredTableData = updatedTableData.filter(
          (item) =>
            item.trigger_instructions
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            item.form_type.toLowerCase().includes(searchTerm.toLowerCase())
        );
        const heads =
          getDataSources?.data?.items?.length >= 0
            ? Object.keys(getDataSources.data.items[0])
            : [];
        setPages(getDataSources?.data?.pages);
        setHeads(heads);
        setTableData(filteredTableData);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    loadForm();
  }, [isFormOpen, searchTerm, page, paginationSize]);

  return (
    <Box px={4} py={2}>
      <Flex justifyContent={"space-between"}>
        <Tooltip
          aria-label="Connected Form"
          placement="right"
          label={
            assistantId
              ? ""
              : "You can connect Form after you’ve created the Assistant"
          }
        >
          <Box>
            <Button
              size="xs"
              type="button"
              isDisabled={!assistantId}
              colorScheme={buttonColorScheme}
              onClick={() => {
                onOpenForm();
              }}
            >
              Assign form
            </Button>
          </Box>
        </Tooltip>
        <Box>
          <Text as={"b"}>{"Connected Forms:"}</Text>{" "}
          <Text as={"b"}>{linkedItem}</Text>
        </Box>
      </Flex>
      {isFormOpen ? (
        <LinkFormAssistantModal
          isOpen={isFormOpen}
          onConfirm={onOpenForm}
          onClose={onFormClose}
          selectedItem={{ id: assistantId }}
          tableData={tableData}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          page={page}
          pages={pages}
          setSearchTerm={setSearchTerm}
          loadForm={loadForm}
          paginationSize={paginationSize}
          setPaginationSize={setPaginationSize}
        />
      ) : null}
    </Box>
  );
};

export default FormFields;
