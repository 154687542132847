import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Textarea,
} from "@chakra-ui/react";
import React from "react";
import { useFormContext } from "react-hook-form";

const BasicDetails = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <Box px={4} py={2}>
      <FormControl isInvalid={errors.name} mb="4">
        <FormLabel htmlFor="name">Name</FormLabel>
        <Input
          placeholder="Name"
          {...register("name", {
            required: "Name is required",
          })}
        />
        {errors.name && (
          <FormErrorMessage>{errors.name.message}</FormErrorMessage>
        )}
      </FormControl>

      <FormControl isInvalid={errors.description} mb="4">
        <FormLabel htmlFor="description">Description</FormLabel>
        <Textarea
          placeholder="Not used by the LLM. Please use system prompt field to finetune the model"
          {...register("description", {
            required: "Description is required",
          })}
          resize="vertical"
        />
        {errors.description && (
          <FormErrorMessage>{errors.description.message}</FormErrorMessage>
        )}
      </FormControl>
    </Box>
  );
};

export default BasicDetails;
