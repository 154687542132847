import { Box, Flex, Tooltip, Checkbox, Text, Input } from "@chakra-ui/react";
import React, { useContext } from "react";
import { useFormContext } from "react-hook-form";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import SelectConversationSync from "./SelectConversationSync";
import SelectContactSync from "./SelectContactSync";
import CustomCheckbox from "./CustomCheckbox";

const ConversationFields = ({ member }) => {
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  const agency = useContext(AgencyContext);
  const { buttonColorScheme, myDetails } = agency;
  const handleConversationCheck = (e) => {
    setValue("attributes.conversation", e.target.checked);
    if (!e.target.checked) {
      setValue("attributes.tool_id", "");
      setValue("attributes.conversation_syncs", []);
    }
  };
  const handleContactCheck = (e) => {
    setValue("attributes.contact", e.target.checked);
    if (!e.target.checked) {
      setValue("attributes.contact_tool_id", "");
      setValue("attributes.contact_syncs", []);
    }
  };
  const handleIsActiveCheck = (e) => {
    setValue("attributes.summarize.is_active", e.target.checked);
    if (!e.target.checked) {
      setValue("attributes.summarize.email", null);
    }
  };
  const toolTipMsg=myDetails && myDetails?.agency_id?`Please contact ${myDetails?.branding?.agency_name || "" } `:"Please upgrade to Platinum or above"

  return (
    <Box px={4} py={2}>
      <Box>
        <CustomCheckbox
          disabled={!member}
          name="has_human_agent"
          onChange={(e) => setValue("has_human_agent", e.currentTarget.checked)}
          isChecked={watch("has_human_agent")}
        >
          <Tooltip
            aria-label="Human Allowed"
            label={
              member
                ? "Select if you want to add the real human to this agent"
                : toolTipMsg
            }
          >
            <Text> Allow Human Handover</Text>
          </Tooltip>
        </CustomCheckbox>
      </Box>
      <Flex gap={2}>
        <Box width={"50%"}>
          <CustomCheckbox
            mt="10px"
            {...register("attributes.conversation")}
            isChecked={watch("attributes.conversation")}
            onChange={handleConversationCheck}
          >
            <Tooltip label="Automatically pushes every conversation to the selected CRM within the contact to keep everything organized. Contact would be identified by the user’s phone number or through a Captured Form converted into a Contact.">
              <Text>Conversation Sync</Text>
            </Tooltip>
          </CustomCheckbox>
          {watch("attributes.conversation") ? (
            <SelectConversationSync
              watch={watch}
              register={register}
              setValue={setValue}
              errors={errors}
            />
          ) : null}
        </Box>

        <Box width={"50%"}>
          <CustomCheckbox
            mt="10px"
            {...register("attributes.contact")}
            isChecked={watch("attributes.contact")}
            onChange={handleContactCheck}
          >
            <Tooltip label="Automatically pushes every contact to the selected CRM to organize all contacts. Contact would be identified by the user’s phone number or through a Captured Form converted into a Contact.">
              <Text>Contact Sync</Text>
            </Tooltip>
          </CustomCheckbox>
          {watch("attributes.contact") ? (
            <SelectContactSync
              watch={watch}
              register={register}
              setValue={setValue}
              errors={errors}
            />
          ) : null}
        </Box>
      </Flex>
      <Flex gap={3} mt={2}>
        <Box width={"50%"}>
          <CustomCheckbox
            mt="10px"
            {...register("attributes.summarize.is_active")}
            isChecked={watch("attributes.summarize.is_active")}
            onChange={handleIsActiveCheck}
          >
            <Text>Get summarized conversation ? (20 text credits)</Text>
          </CustomCheckbox>
        </Box>
        <Box width={"50%"}>
          {watch("attributes.summarize.is_active") && (
            <>
              <Input
                type="email"
                placeholder="Email"
                {...register("attributes.summarize.email", {
                  required: "This field is required",
                })}
              />
              {errors?.attributes?.summarize?.email && (
                <p style={{ color: "red", fontSize: "11px" }}>
                  {errors?.attributes?.summarize?.email?.message}
                </p>
              )}
            </>
          )}
        </Box>
      </Flex>
    </Box>
  );
};

export default ConversationFields;
